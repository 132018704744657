<template>
  <div>
    <v-tabs centered color="cyan" grow dark v-model="tab">
      <v-tabs-slider color="yellow"></v-tabs-slider>
      <v-tab
        v-for="entidad in destinosLocal"
        v-bind:key="entidad.origen_codigo"
        dark
      >{{ entidad.origen_nombre }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="entidad in destinosLocal"
        v-bind:key="entidad.origen_codigo"
      >
        <v-simple-table
          class="ma-4"
          dense
        >
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center">Articulo</th>
              <th class="text-left">Descripción</th>
              <th class="text-center">A reasignar</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(art, index) in entidad.articulos"
              :key="index"
            >
              <td class="text-center">{{ art.articulo_codigo }}</td>
              <td class="text-left">{{ art.articulo_nombre }}</td>
              <td class="text-center">{{ art.asignado }}</td>
            </tr>
          </tbody>
        </template>
        </v-simple-table>
      </v-tab-item>
    </v-tabs-items>
    
  </div>
</template>

<script>
export default {
  props: {
    destinos: {
      type: Array,
      default: []
    },
  },
  computed: {
    destinosLocal: {
      get(){
        return this.destinos
      }
    }
  },
  data() {
    return {
      tab: null
    }
  },
}
</script>

<style>

</style>