<template>
  <v-col cols="12">
    <v-divider></v-divider>
    <div class="d-flex align-center py-3" style="font-size: 18px;">
      <v-row>
        <v-col cols="12" sm="6" md="4" lg="3" xl="2">
          <v-icon left>fas fa-user</v-icon>
          Datos cliente
          <v-btn
            v-if="newvta"
            color="info"
            class="ml-2"
            title="Buscar cliente"
            small
            icon
            @click="modal_clientes = true"
          >
            <v-icon small>fas fa-search</v-icon>
          </v-btn>
        </v-col>
        <v-col v-if="newvta" cols="8" sm="6" md="4" lg="3" xl="2">
          <v-expand-transition>
            <v-autocomplete
              v-if="empleado == 1 && aplica_desc == 1"
              v-model="beneficio_empl"
              label="Beneficio Empleado"
              style="background: var(--v-rowwar-base);"
              :items="beneficios"
              hide-details
              outlined
              dense
              @change="$emit('setBeneficio', beneficio_empl)"
            ></v-autocomplete>
          </v-expand-transition>
          <v-expand-transition>
            <v-text-field
              v-if="empleado == 1 && aplica_desc == 0"
              v-model="advertencia"
              label="Beneficio Empleado"
              style="background: var(--v-rowerr-base);"
              hide-details
              readonly
              outlined
              dense
            ></v-text-field>
          </v-expand-transition>
        </v-col>
      </v-row>
    </div>
    <v-form ref="formCliente">
      <v-row>
        <v-col cols="12" class="d-flex pb-0">
          <template v-if="newvta">
            <v-icon
              class="pb-7"
              :color="$refs.vtfEscaner && $refs.vtfEscaner.isFocused ? 'info' : ''"
              large
              left
            >
              fas fa-barcode
            </v-icon>
            <v-text-field
              v-model.trim="dni_escaneado"
              ref="vtfEscaner"
              class="circulitos"
              placeholder="Escanne el DNI del Cliente aquí"
              background-color="rowsel"
              autocomplete="off"
              type="text"
              tabindex="1"
              outlined
              @keypress.native.enter="$refs.vtfEscaner.blur()"
              @blur="escanearDni()"
            ></v-text-field>
          </template>
        </v-col>
        <v-col v-if="newvta" cols="6" sm="3" md="2" class="py-0">
          <v-text-field-integer
            v-if="cliente.tipo_doc == 1"
            v-model.trim="documento"
            label="Nº Documento"
            v-bind:properties="{
              tabindex: 1,
              readonly: bloquear_doc,
              filled: bloquear_doc,
              clearable: true,
              outlined: true,
              dense: true
            }"
            v-bind:options="{
              inputMask: '##-########-#',
              outputMask: '###########',
              empty: null
            }"
            @blur="buscarCliente()"
            @change="setCuit()"
          />
          <v-text-field
            v-else
            v-model.trim="documento"
            ref="vtfDocument"
            label="Nº Documento"
            tabindex="1"
            :readonly="bloquear_doc || (cliente_escaneado.cadena && !existe_cliente)"
            :filled="bloquear_doc || (cliente_escaneado.cadena && !existe_cliente)"
            clearable
            outlined
            dense
            @blur="!cliente_escaneado.cadena || existe_cliente ? buscarCliente() : ''"
            @change="setDoc()"
          ></v-text-field>
        </v-col>
        <v-col v-else cols="6" sm="3" md="2" class="py-0">
          <v-text-field
            v-model.trim="cliente.documento"
            label="Nº Documento"
            tabindex="1"
            outlined
            readonly
            filled
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3" class="py-0">
          <v-autocomplete
            v-model.trim="cliente.cond_iva"
            ref="condIVA"
            label="Condición IVA"
            item-text="condicion_iva_nombre"
            item-value="condicion_iva_codigo"
            tabindex="1"
            :items="cond_iva"
            :readonly="!newvta || (cliente_escaneado.cadena && !existe_cliente)"
            :filled="!newvta || (cliente_escaneado.cadena && !existe_cliente)"
            outlined
            dense
            @change="setTipoDoc(1)"
          ></v-autocomplete>
        </v-col>
        <v-col cols="6" sm="3" md="2" class="pt-0" :class="!newvta ? 'pb-0' : ''">
          <v-autocomplete
            v-model.trim="cliente.tipo_doc"
            label="Tipo Documento"
            item-text="nombre"
            item-value="id"
            tabindex="1"
            :items="tipos_doc"
            :readonly="bloquear_tipo_doc || !newvta || (cliente_escaneado.cadena && !existe_cliente)"
            :filled="bloquear_tipo_doc || !newvta || (cliente_escaneado.cadena && !existe_cliente)"
            :hint="cliente.tipo_doc == 1 && cliente.num_doc ? `DNI: ${cliente.num_doc}` :
              (cliente.tipo_doc != 1 && cliente.cuit ? `CUIT: ${cliente.cuit}` : '')"
            persistent-hint
            outlined
            dense
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="9" md="5" class="py-0">
          <v-row :no-gutters="$vuetify.breakpoint.xs">
            <v-col cols="12" sm="6">
              <v-text-field
                v-model.trim="cliente.apellido"
                label="Apellido"
                tabindex="1"
                autocomplete="off"
                :readonly="!newvta || (cliente_escaneado.cadena && !existe_cliente)"
                :filled="!newvta || (cliente_escaneado.cadena && !existe_cliente)"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model.trim="cliente.nombre"
                label="Nombre"
                tabindex="1"
                autocomplete="off"
                :readonly="!newvta || (cliente_escaneado.cadena && !existe_cliente)"
                :filled="!newvta || (cliente_escaneado.cadena && !existe_cliente)"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6" sm="3" md="2" class="py-0">
          <FechaPicker
            v-model="cliente.fecha_nac"
            label="Fecha Nacimiento"
            :disabled="bloquear_fec_nac || !newvta"
          />
        </v-col>
        <v-checkbox
          v-if="newvta"
          v-model="cliente.cumpleanos"
          class="py-0 px-3"
          label="Cumpleaños"
          :true-value="1"
          :false-value="0"
          :disabled="cliente.fecha_nac ? moment(parseDate(cliente.fecha_nac)).format('MM') != moment(new Date()).format('MM') : true"
          hide-details
          dense
          @change="validarCumple()"
        ></v-checkbox>
        <v-col cols="6" sm="3" md="2" class="py-0">
          <v-text-field
            v-model.trim="cliente.telefono"
            ref="vtfTelef"
            label="Teléfono"
            tabindex="1"
            autocomplete="off"
            :readonly="!newvta"
            :filled="!newvta"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-row class="py-3 ml-0 mr-0" :style="`width: ${$vuetify.breakpoint.smAndUp ? 50 : 100}%;`">
          <v-col cols="12" sm="6" class="py-0">
            <v-text-field
              v-model.trim="cliente.email"
              label="Email Cliente"
              tabindex="1"
              autocomplete="off"
              :rules="[rules.email]"
              :readonly="!newvta"
              :filled="!newvta"
              validate-on-blur
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" class="py-0">
            <v-text-field
              v-model.trim="cliente.email_referido"
              label="Email Referido"
              tabindex="1"
              autocomplete="off"
              :rules="[rules.email]"
              :readonly="!newvta"
              :filled="!newvta"
              validate-on-blur
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-col cols="6" sm="4" md="3" class="py-0">
          <v-autocomplete
            v-model="cliente.provincia"
            label="Provincia"
            item-text="nombre"
            item-value="codigo"
            tabindex="1"
            :items="provincias_tmp"
            :readonly="!newvta"
            :filled="!newvta"
            outlined
            dense
            @change="cliente.localidad = null; getLocalidades()"
          ></v-autocomplete>
        </v-col>
        <v-col cols="6" sm="4" md="3" class="py-0">
          <v-autocomplete
            v-if="newvta"
            v-model="cliente.localidad"
            label="Localidad"
            item-text="nombre"
            item-value="codigo"
            tabindex="1"
            :items="localidades"
            outlined
            dense
          ></v-autocomplete>
          <v-text-field
            v-else
            v-model.trim="cliente.localidad_nombre"
            label="Localidad"
            tabindex="1"
            outlined
            readonly
            filled
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" class="py-0">
          <v-text-field
            v-model.trim="cliente.direccion"
            label="Dirección"
            tabindex="1"
            autocomplete="off"
            :readonly="!newvta"
            :filled="!newvta"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-expand-transition>
          <v-col
            v-if="newvta && cliente.cond_iva != null && cond_iva.filter(ci => ci.condicion_iva_codigo == cliente.cond_iva).length > 0 && Object.keys(p_local).length > 0"
            cols="12" class="py-0"
          >
            <v-alert
              class="mx-auto text-h6"
              max-width="450"
              type="warning"
              color="orange"
              border="left"
              text
            >
              La Factura a emitir será de
              <strong>
                TIPO "{{ p_local.empresa_cond_iva == 6 ?
                        cond_iva.find(ci => ci.condicion_iva_codigo == cliente.cond_iva).tipo_fac_mono :
                        cond_iva.find(ci => ci.condicion_iva_codigo == cliente.cond_iva).tipo_fac
                      }}"
              </strong>
            </v-alert>
          </v-col>
        </v-expand-transition>
        <v-expand-transition>
          <v-col v-if="cliente_nuevo" cols="12" class="py-0">
            <v-alert
              type="info"
              border="left"
            >
              El cliente no se encuentra en la base de datos, al completar los datos se dara de alta al nuevo Cliente.
            </v-alert>
          </v-col>
        </v-expand-transition>
      </v-row>
    </v-form>
    <!-- modal -->
    <buscar-cliente
      v-model="modal_clientes"
      @selected="seleccionarCliente"
    />
  </v-col>
</template>

<script>
import { REGEX_EMAIL } from '../../../util/utils'
import { parseDate } from '../../../util/utils'
import BuscarCliente from './BuscarCliente.vue'
import FechaPicker from '../../util/FechaPicker.vue'
import moment from 'moment'

export default {
  data () {
    return {
      moment: moment,
      parseDate: parseDate,
      beneficio_empl: 1,
      modal_clientes: false,
      existe_cliente: false,
      cliente_nuevo: false,
      bloquear_tipo_doc: false,
      bloquear_fec_nac: false,
      bloquear_doc: false,
      advertencia: 'No aplica (supera el límite permitido)',
      documento: '',
      dni_escaneado: '',
      num_doc_original: '',
      cuit_original: '',
      localidades: [],
      beneficios: [
        { text: 'Precio Mayorista', value: 1 },
        { text: 'Cuotas Sin Interés', value: 2 }
      ],
      rules: {
        email: value => {
          if (value) return REGEX_EMAIL.test(value) || 'Email no válido'
          else return true
        }
      }
    }
  },
  async created () {
    this.limpiarCliente()
  },
  props: {
    p_cliente: Object,
    p_cliente_escaneado: Object,
    p_local: Object,
    p_init: Object,
    newvta: Boolean,
    empleado: Number,
    aplica_desc: Number,
    beneficio_empleado: Number
  },
  computed: {
    cliente: {
      get () {
        return this.p_cliente
      },
      set (value) {
        this.$emit('update:p_cliente', value)
      }
    },
    cliente_escaneado: {
      get () {
        return this.p_cliente_escaneado
      },
      set (value) {
        this.$emit('update:p_cliente_escaneado', value)
      }
    },
    provincias_tmp: {
      get () {
        return this.p_init.provincias
      }
    },
    tipos_doc: {
      get () {
        return this.p_init.tipos_doc
      }
    },
    cond_iva: {
      get () {
        return this.p_init.cond_iva
      }
    }
  },
  components: {
    BuscarCliente,
    FechaPicker
  },
  watch: {
    beneficio_empleado (newVal, oldVal) {
      if (newVal != oldVal) {
        this.beneficio_empl = newVal
      }
    },
    cliente_nuevo (val) {
      this.$emit('setNuevo', val)
    },
    p_cliente (val) {
      if (Object.keys(val).length == 0) this.limpiarCliente()
    },
    documento (val) {
      if (val == null) {
        this.limpiarCliente()
        this.$emit('descuento', 1, this.documento)
      }
    },
    'cliente.fecha_nac': {
      handler: function (newVal, oldVal) {
        if (!this.newvta) return
        // si esta tildado cumpleaños y cambio el mes entonces vuelvo a buscar sin cumpleaños
        if (this.cliente.cumpleanos == 1 && (!newVal || moment(parseDate(newVal)).format('MM') != moment(new Date()).format('MM'))) {
          this.cliente.cumpleanos = 0
          this.$emit('descuento', 1, this.documento)
        }
      },
      deep: true
    },
    'cliente.tipo_doc': {
      handler: function (newVal, oldVal) {

        // si es nuevo seteo el documento en el campo q corresponda
        if (this.cliente_nuevo) {
          if (!this.cliente.tipo_doc) {
            this.cliente.cuit = null
            this.cliente.num_doc = null
          } else if (this.cliente.tipo_doc == 1) {
            this.cliente.cuit = JSON.parse(JSON.stringify(this.documento))
            this.cliente.num_doc = null
          } else {
            this.cliente.cuit = null
            this.cliente.num_doc = JSON.parse(JSON.stringify(this.documento))
          }
        }

        // si es nuevo no bloqueo nada ni reemplazo nada
        if (!this.existe_cliente) {
          this.bloquear_doc = false
          return
        }
        // si el tipo nuevo es nulo (se limpio por iva) y si el cliente existe => limpio el doc y quiebro
        else if (newVal == null) {
          this.documento = ''
          this.setOriginales()
          return
        }

        // si el nuevo tipo no es cuit y exite dni original
        if (newVal != 1 && this.num_doc_original) {
          this.bloquear_doc = true
          this.documento = JSON.parse(JSON.stringify(this.num_doc_original))
        }
        // si el nuevo tipo es cuit y existe cuit original
        else if (newVal == 1 && this.cuit_original) {
          this.bloquear_doc = true
          this.documento = JSON.parse(JSON.stringify(this.cuit_original))
          if (this.documento != null) this.documento = this.documento.toString().substring(0, 11)
        }
        else {
          this.bloquear_doc = false
          this.documento = ''
        }
        this.setOriginales()

      },
      deep: true
    }
  },
  methods: {
    async validarCumple () {
      // si el cliente existe valido si ya utilizo el descuento
      if (this.cliente.codigo && this.cliente.cumpleanos == 1) {
        this.$store.state.loading = true
        let result = await this.$store.dispatch('ventas/validar_cumple', this.cliente.codigo)
        this.$store.state.loading = false

        if (result.exito != 1) {
          this.cliente.cumpleanos = 0
          this.$store.dispatch('show_snackbar', {
            text: result.message,
            color: 'deep-orange'
          })
        }
      }
      this.$emit('descuento', 1, this.documento)
    },
    setOriginales () {
      this.cliente.cuit = JSON.parse(JSON.stringify(this.cuit_original))
      this.cliente.num_doc = JSON.parse(JSON.stringify(this.num_doc_original))
    },
    setCuit () {
      if (this.cliente_nuevo) return
      this.cliente.cuit = JSON.parse(JSON.stringify(this.cuit_original ? this.cuit_original : this.documento))
    },
    setDoc () {
      if (this.cliente_nuevo) return
      this.cliente.num_doc = JSON.parse(JSON.stringify(this.num_doc_original ? this.num_doc_original : this.documento))
    },
    async buscarCliente (escaneado, datos_scan) {
      // si no hay documento o el cliente existe => salgo
      if (!this.documento || this.existe_cliente) return

      this.bloquear_fec_nac = escaneado == 1
      this.cliente.cumpleanos = 0

      if (escaneado == 1) {
        this.cliente_escaneado = {
          codigo: null,
          cadena: datos_scan.cadena,
          num_tramite: datos_scan.num_tramite,
          sexo: datos_scan.sexo,
          ejemplar: datos_scan.ejemplar,
          nacimiento: datos_scan.nacimiento,
          fecha_tramite: datos_scan.fecha_tramite
        }
      }

      this.$store.state.loading = true
      let result = await this.$store.dispatch('clientes/buscar_cliente', { documento: this.documento })
      this.$store.state.loading = false

      if (result.exito == 1) {
        if (result.size == 1) {
          this.$refs.condIVA.focus()
          this.seleccionarCliente(result.data[0])
          if (escaneado == 1) {
            this.cliente_escaneado.codigo = this.cliente.codigo
            this.cliente.fecha_nac = this.cliente_escaneado.nacimiento
          }
        }
        else {
          this.cliente_nuevo = true
          if (escaneado == 1) {
            this.$refs.vtfTelef.focus()
            this.cliente = {
              codigo: null,
              nombre: datos_scan.nombres,
              apellido: datos_scan.apellidos,
              tipo_doc: 2,
              cond_iva: 5,
              telefono: null,
              email: null,
              email_referido: null,
              provincia: null,
              localidad: null,
              direccion: null,
              mayorista: null,
              fecha_nac: datos_scan.nacimiento,
              cumpleanos: 0,
              fraude: null
            }
          } else {
            this.$refs.condIVA.focus()
            this.cliente = {
              codigo: null,
              nombre: null,
              apellido: null,
              tipo_doc: null,
              cond_iva: null,
              telefono: null,
              email: null,
              email_referido: null,
              provincia: null,
              localidad: null,
              direccion: null,
              mayorista: null,
              fecha_nac: null,
              cumpleanos: 0,
              fraude: null
            }
          }
          this.$emit('descuento', 1, this.documento)
        }
      } else {
        this.limpiarCliente()
        this.$emit('descuento', 1, this.documento)
        this.$refs.vtfDocument.focus()
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'error'
        })
      }
    },
    escanearDni () {
      if (!this.dni_escaneado) return

      this.dni_escaneado = this.dni_escaneado.replaceAll('"', '@')
      let cantidad = this.dni_escaneado.split('@').length
      
      if (cantidad < 8) {
        this.dni_escaneado = ''
        return this.$swal.fire({
          icon: 'warning',
          title: 'Lectura incorrecta del DNI',
          text: 'Por favor, vuelva a escanear el DNI del Cliente'
        })
      }

      let dni = ''
      let nombres = ''
      let apellidos = ''
      let nacimiento = ''
      let sexo = ''
      let ejemplar = ''
      let fecha_tramite = ''
      let num_tramite = ''
      let cadena_escaneada = JSON.parse(JSON.stringify(this.dni_escaneado))
      
      // dni viejo
      if (cadena_escaneada[0] == '@') {
        dni = cadena_escaneada.substring(1, this.getPosition(cadena_escaneada, '@', 2)).replaceAll(/\s/g,'')
        ejemplar = cadena_escaneada.substring(this.getPosition(cadena_escaneada, '@', 2) + 1, this.getPosition(cadena_escaneada, '@', 3))
        apellidos = cadena_escaneada.substring(this.getPosition(cadena_escaneada, '@', 4) + 1, this.getPosition(cadena_escaneada, '@', 5))
        nombres = cadena_escaneada.substring(this.getPosition(cadena_escaneada, '@', 5) + 1, this.getPosition(cadena_escaneada, '@', 6))
        nacimiento = cadena_escaneada.substring(this.getPosition(cadena_escaneada, '@', 7) + 1, this.getPosition(cadena_escaneada, '@', 8))
        sexo = cadena_escaneada.substring(this.getPosition(cadena_escaneada, '@', 8) + 1, this.getPosition(cadena_escaneada, '@', 9))
        fecha_tramite = cadena_escaneada.substring(this.getPosition(cadena_escaneada, '@', 9) + 1, this.getPosition(cadena_escaneada, '@', 10))
        num_tramite = cadena_escaneada.substring(this.getPosition(cadena_escaneada, '@', 10) + 1, this.getPosition(cadena_escaneada, '@', 11))
      }
      // dni nuevo
      else {
        num_tramite = cadena_escaneada.substring(0, this.getPosition(cadena_escaneada, '@', 1))
        apellidos = cadena_escaneada.substring(this.getPosition(cadena_escaneada, '@', 1) + 1, this.getPosition(cadena_escaneada, '@', 2))
        nombres = cadena_escaneada.substring(this.getPosition(cadena_escaneada, '@', 2) + 1, this.getPosition(cadena_escaneada, '@', 3))
        sexo = cadena_escaneada.substring(this.getPosition(cadena_escaneada, '@', 3) + 1, this.getPosition(cadena_escaneada, '@', 4))
        dni = cadena_escaneada.substring(this.getPosition(cadena_escaneada, '@', 4) + 1, this.getPosition(cadena_escaneada, '@', 5))
        ejemplar = cadena_escaneada.substring(this.getPosition(cadena_escaneada, '@', 5) + 1, this.getPosition(cadena_escaneada, '@', 6))
        nacimiento = cadena_escaneada.substring(this.getPosition(cadena_escaneada, '@', 6) + 1, this.getPosition(cadena_escaneada, '@', 7))
        fecha_tramite = cadena_escaneada.substring(this.getPosition(cadena_escaneada, '@', 7) + 1, this.getPosition(cadena_escaneada, '@', 7) + 11)
      }

      // reemplazo las ñ
      apellidos = apellidos.replaceAll('NXX', 'Ñ')
      nombres = nombres.replaceAll('NXX', 'Ñ')

      this.limpiarCliente()
      this.documento = dni
      this.buscarCliente(1, {
        cadena: cadena_escaneada,
        nombres: nombres,
        apellidos: apellidos,
        nacimiento: nacimiento,
        sexo: sexo,
        ejemplar: ejemplar,
        fecha_tramite: fecha_tramite,
        num_tramite: num_tramite
      })

    },
    seleccionarCliente (cliente) {
      this.existe_cliente = true
      this.cliente_nuevo = false
      this.num_doc_original = cliente.num_doc ? JSON.parse(JSON.stringify(cliente.num_doc)) : ''
      this.cuit_original =  cliente.cuit ? JSON.parse(JSON.stringify(cliente.cuit)) : ''

      this.documento = cliente.tipo_doc == 1 ? cliente.cuit : cliente.num_doc

      if (cliente.fecha_nac) this.bloquear_fec_nac = true
      
      Object.assign(this.cliente, cliente)
      this.getLocalidades()
      this.setTipoDoc()
      this.$emit('descuento', 1, this.documento)
    },
    setTipoDoc (clear) {
      this.bloquear_tipo_doc = false
      const condicion_iva = this.cond_iva.find(ci => ci.condicion_iva_codigo == this.cliente.cond_iva)
      if (condicion_iva && condicion_iva.tipo_doc != null) {
        this.cliente.tipo_doc = condicion_iva.tipo_doc
        this.bloquear_tipo_doc = true
      } else if (clear == 1) {
        this.cliente.tipo_doc = null
      }
    },
    async getLocalidades () {
      this.localidades = []
      if (this.cliente.provincia) {
        this.$store.state.loading = true
        await this.$store.dispatch('get_localidades_tmp', this.cliente.provincia)
          .then((result) => {
            this.localidades = result.data
          }).catch((err) => {
            this.$store.dispatch('show_snackbar', {
              text: err.message,
              color: 'error'
            })
          })
        this.$store.state.loading = false
      }
    },
    getPosition (string, subString, index) {
      return string.split(subString, index).join(subString).length
    },
    limpiarCliente () {
      this.beneficio_empl = 1
      this.existe_cliente = false
      this.cliente_nuevo = false
      this.bloquear_tipo_doc = false
      this.bloquear_fec_nac = false
      this.bloquear_doc = false
      this.documento = ''
      this.dni_escaneado = ''
      this.num_doc_original = ''
      this.cuit_original = ''
      this.localidades = []
      this.cliente_escaneado = {
        codigo: null,
        cadena: null,
        sexo: null,
        nacimiento: null,
        ejemplar: null,
        num_tramite: null,
        fecha_tramite: null
      }
      this.cliente = {
        cuit: null,
        num_doc: null,
        codigo: null,
        nombre: null,
        apellido: null,
        tipo_doc: null,
        cond_iva: null,
        telefono: null,
        email: null,
        email_referido: null,
        provincia: null,
        localidad: null,
        direccion: null,
        mayorista: null,
        fecha_nac: null,
        cumpleanos: 0,
        fraude: null
      }
    }
  }
}
</script>