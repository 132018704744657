<template>
  <v-dialog
    v-model="dialog"
    width="900"
    scrollable
  >
    <v-card>
      <v-card-title style="word-break: normal;">
        {{ forma_pago.descripcion }}
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pb-0" :class="$vuetify.breakpoint.xs ? 'px-3' : ''">
        <!-- DATOS DEL BANCO -->
        <v-card
          v-show="forma_pago.solicita_banco == 1"
          class="mb-3"
          outlined
        >
          <v-card-title class="py-3">
            <v-icon color="green" left>fas fa-university</v-icon>
            Datos del banco
          </v-card-title>
          <v-card-text class="pt-4">
            <v-form ref="formBanco">
              <v-row>
                <v-col cols="6" sm="3" class="py-0">
                  <v-autocomplete
                    v-model="datos.banco_codigo"
                    label="Banco"
                    item-text="nombre"
                    item-value="codigo"
                    :items="bancos.filter(ba => ba.cuentas > 0)"
                    :readonly="!editar"
                    :filled="!editar"
                    :rules="[rules.required]"
                    validate-on-blur
                    outlined
                    dense
                    @change="datos.banco_cuenta = null; cbu = null; setCuenta()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="4" class="py-0">
                  <v-autocomplete
                    v-model="datos.banco_cuenta"
                    label="Cuenta"
                    item-text="numero"
                    item-value="codigo"
                    :items="all_cuentas.filter(cu => cu.inhabilitada != 1 && cu.banco_codigo == datos.banco_codigo && cu.empresa_codigo == p_ptovta.empresa)"
                    :readonly="!editar"
                    :filled="!editar"
                    :rules="[rules.required]"
                    validate-on-blur
                    outlined
                    dense
                    @change="setCBU()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="4" class="py-0">
                  <v-text-field
                    v-model="cbu"
                    label="CBU"
                    outlined
                    readonly
                    filled
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="3" class="py-0">
                  <FechaPicker
                    v-model="datos.banco_fecha"
                    label="Fecha"
                    :disabled="!editar"
                  />
                </v-col>
                <v-col cols="6" sm="4" class="py-0">
                  <v-autocomplete
                    v-model="datos.banco_tipo_movimiento"
                    label="Movimiento"
                    item-text="nombre"
                    item-value="codigo"
                    :items="tipos_movimiento.filter(tm => tm.inhabilitado != 1 || tm.codigo == datos.banco_tipo_movimiento)"
                    :readonly="!editar"
                    :filled="!editar"
                    :rules="[rules.required]"
                    validate-on-blur
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="4" class="py-0">
                  <v-text-field
                    v-model.trim="datos.banco_comprobante"
                    label="Nº Comprobante"
                    :readonly="!editar"
                    :filled="!editar"
                    :rules="[rules.required]"
                    validate-on-blur
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
        <!-- DATOS DE LA TARJETA -->
        <v-card
          v-show="((forma_pago.estado_solicitud == 2 || forma_pago.estado_solicitud == 0 || forma_pago.lapos_integrado == 1) ||
                 (forma_pago.estado_solicitud != 2 && forma_pago.cobro_online == 1)) && forma_pago.solicita_tarjeta == 1"
          class="mb-3"
          outlined
        >
          <v-card-title class="py-3">
            <v-icon color="cyan" left>fas fa-credit-card</v-icon>
            Datos de la tarjeta
            <v-spacer></v-spacer>
            <v-row
              v-if="forma_pago.cobro_online == 0 && forma_pago.lapos_integrado == 0 && forma_pago.id == null"
              class="d-flex align-center my-0"
            >
              <v-col cols="12" sm="" class="d-flex justify-start justify-sm-end" style="word-break: normal;">
                <v-checkbox
                  v-model="reutilizacion"
                  label="Reutilizar Cupón"
                  class="mt-n1"
                  hide-details
                  @change="limpiarReutilizacion()"
                ></v-checkbox>
              </v-col>
              <v-expand-x-transition>
                <div v-if="reutilizacion" class="d-flex mx-3">
                  <v-text-field
                    v-model.trim="ipg_trans_id"
                    label="N° Transacción"
                    hide-details
                    outlined
                    dense
                    @change="getCobroLibre()"
                  ></v-text-field>
                </div>
              </v-expand-x-transition>
            </v-row>
          </v-card-title>
          <v-card-text class="pt-4">
            <!-- lapos o desintegrado -->
            <v-form
              v-show="forma_pago.estado_solicitud == 2 || forma_pago.estado_solicitud == 0 || forma_pago.lapos_integrado == 1"
              ref="formTarjeta"
            >
              <v-row>
                <v-col cols="6" sm="3" class="py-0 pr-md-0">
                  <v-autocomplete
                    v-model="forma_pago.tarjeta_codigo"
                    label="Tarjeta"
                    item-text="nombre"
                    item-value="codigo"
                    :items="tarjetas"
                    outlined
                    readonly
                    filled
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" class="py-0 pl-md-6">
                  <v-autocomplete
                    v-model="banco"
                    label="Banco"
                    item-text="nombre"
                    item-value="id"
                    :items="bancos_pais.filter(bp => bp.inhabilitado != 1 || bp.id == forma_pago.banco)"
                    :readonly="forma_pago.estado_solicitud == 2 || !editar"
                    :filled="forma_pago.estado_solicitud == 2 || !editar"
                    :rules="editar ? [rules.required] : []"
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-0">
                  <v-text-field-integer
                    v-model="datos.tarjeta_numero"
                    label="4 últimos"
                    v-bind:properties="{
                      readonly: forma_pago.estado_solicitud == 2 || forma_pago.lapos_integrado == 1 || !editar || reutilizacion,
                      filled: forma_pago.estado_solicitud == 2 || forma_pago.lapos_integrado == 1 || !editar || reutilizacion,
                      rules: !(forma_pago.estado_solicitud == 2 || forma_pago.lapos_integrado == 1 || !editar || reutilizacion) ? [rules.required] : [],
                      validateOnBlur: true,
                      outlined: true,
                      dense: true
                    }"
                    v-bind:options="{
                      inputMask: '####',
                      outputMask: '####',
                      empty: null
                    }"
                  />
                </v-col>
                <template v-if="forma_pago.cuotas == 1">
                  <v-col cols="6" sm="2" class="py-0 pr-md-6">
                    <v-text-field
                      v-model="forma_pago.cant_cuotas"
                      label="Cuotas"
                      outlined
                      readonly
                      filled
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" sm="3" md="2" class="py-0 pl-md-0">
                    <text-field-money
                      v-model="forma_pago.monto_cuotas"
                      label="Importe x Cuota"
                      v-bind:properties="{
                        prefix: '$',
                        readonly: true,
                        filled: true
                      }"
                    />
                  </v-col>
                </template>
                <v-col cols="6" sm="2" class="py-0">
                  <v-checkbox
                    v-model="datos.cliente"
                    label="Cliente"
                    class="mt-0 pb-3"
                    :disabled="forma_pago.estado_solicitud == 2 || !editar || reutilizacion"
                    dense
                    @click="datos.terceros ? (datos.terceros = false, datos.titular = '', datos.titular_dni = '') : ''"
                  ></v-checkbox>
                </v-col>
                <v-col cols="6" sm="2" class="py-0">
                  <v-checkbox
                    v-model="datos.terceros"
                    label="Terceros"
                    class="mt-0 pb-3"
                    :disabled="forma_pago.estado_solicitud == 2 || !editar || reutilizacion"
                    dense
                    @click="datos.cliente ? datos.cliente = false : ''"
                  ></v-checkbox>
                </v-col>
                <template v-if="datos.terceros">
                  <v-col cols="6" sm="4" md="5" class="py-0">
                    <v-text-field
                      v-model.trim="datos.titular"
                      label="Titular"
                      :readonly="forma_pago.estado_solicitud == 2 || !editar || reutilizacion"
                      :filled="forma_pago.estado_solicitud == 2 || !editar || reutilizacion"
                      :rules="datos.terceros && !reutilizacion ? [rules.required] : []"
                      validate-on-blur
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" sm="4" md="3" class="py-0">
                    <v-text-field
                      v-model.trim="datos.titular_dni"
                      label="DNI"
                      type="number"
                      :readonly="forma_pago.estado_solicitud == 2 || !editar || reutilizacion"
                      :filled="forma_pago.estado_solicitud == 2 || !editar || reutilizacion"
                      :rules="datos.terceros && !reutilizacion ? [rules.required] : []"
                      validate-on-blur
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </template>
              </v-row>
            </v-form>
            <!-- cobro online -->
            <v-form
              v-show="forma_pago.estado_solicitud != 2 && forma_pago.cobro_online == 1"
              ref="formCobro"
            >
              <v-row>
                <v-col cols="6" sm="3" class="py-0 pr-md-0">
                  <v-autocomplete
                    v-model="forma_pago.tarjeta_codigo"
                    label="Tarjeta"
                    item-text="nombre"
                    item-value="codigo"
                    :items="tarjetas"
                    outlined
                    readonly
                    filled
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" class="py-0 pl-md-6">
                  <v-autocomplete
                    v-model="banco"
                    label="Banco"
                    item-text="nombre"
                    item-value="id"
                    :items="bancos_pais.filter(bp => bp.inhabilitado != 1 || bp.id == forma_pago.banco)"
                    :readonly="!editar"
                    :filled="!editar"
                    :rules="editar ? [rules.required] : []"
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="4" class="py-0">
                  <v-text-field-integer
                    v-model="datos.tarjeta_numero"
                    label="Nº Tarjeta"
                    v-bind:properties="{
                      readonly: !editar,
                      filled: !editar,
                      rules: [rules.tarjeta],
                      'validate-on-blur': true,
                      outlined: true,
                      dense: true
                    }"
                    v-bind:options="{
                      inputMask: cc_mask,
                      outputMask: cc_digits,
                      empty: null
                    }"
                  />
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-0">
                  <v-text-field-simplemask
                    v-model="datos.tarjeta_vencimiento"
                    label="Vencimiento"
                    v-bind:properties="{
                      placeholder: 'MM/AA',
                      readonly: !editar,
                      filled: !editar,
                      rules: editar ? [rules.required, rules.vencimiento] : [],
                      'persistent-placeholder': true,
                      'validate-on-blur': true,
                      outlined: true,
                      dense: true
                    }"
                    v-bind:options="{
                      inputMask: '##/##',
                      outputMask: '##/##',
                      empty: null
                    }"
                  />
                </v-col>
                <v-col cols="6" sm="3" md="2" class="d-flex align-start py-0">
                  <v-text-field-integer
                    v-model="datos.codigo_seguridad"
                    label="CVV"
                    v-bind:properties="{
                      readonly: !editar,
                      filled: !editar,
                      rules: editar ? [rules.required] : [],
                      'validate-on-blur': true,
                      outlined: true,
                      dense: true
                    }"
                    v-bind:options="{
                      inputMask: '####',
                      outputMask: '####',
                      empty: null
                    }"
                  />
                  <v-tooltip color="white" bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        color="info"
                        class="ml-2"
                        style="margin-top: 3px;"
                      >
                        fas fa-question-circle
                      </v-icon>
                    </template>
                    <v-img
                      src="../../../assets/cvv_info.webp"
                      width="350"
                    ></v-img>
                  </v-tooltip>
                </v-col>
                <template v-if="forma_pago.cuotas == 1">
                  <v-col cols="6" sm="2" class="py-0">
                    <v-text-field
                      v-model="forma_pago.cant_cuotas"
                      label="Cuotas"
                      outlined
                      readonly
                      filled
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" sm="3" xl="2" class="py-0">
                    <text-field-money
                      v-model="forma_pago.monto_cuotas"
                      label="Importe x Cuota"
                      v-bind:properties="{
                        prefix: '$',
                        readonly: true,
                        filled: true
                      }"
                    />
                  </v-col>
                </template>
                <v-col cols="6" sm="4" class="py-0">
                  <v-text-field
                    v-model.trim="datos.titular"
                    label="Titular"
                    :readonly="!editar"
                    :filled="!editar"
                    :rules="[rules.required]"
                    validate-on-blur
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="4" md="3" class="py-0">
                  <v-text-field
                    v-model.trim="datos.titular_dni"
                    label="Documento"
                    type="number"
                    :readonly="!editar"
                    :filled="!editar"
                    :rules="[rules.required]"
                    validate-on-blur
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
        <!-- DATOS DEL CUPON -->
        <v-card
          v-show="(forma_pago.estado_solicitud == 2 || forma_pago.estado_solicitud == 0 || forma_pago.lapos_integrado == 1) && forma_pago.solicita_banco != 1"
          class="mb-3"
          outlined
        >
          <v-card-title class="py-3">
            <v-icon color="indigo" left>fas fa-receipt</v-icon>
            Cupón de pago
          </v-card-title>
          <v-card-text class="pt-4">
            <v-form ref="formCP">
              <v-row>
                <v-col cols="6" sm="3" class="py-0">
                  <FechaPicker
                    v-model="datos.fecha_cobro"
                    label="Fecha cobro"
                    :disabled="forma_pago.estado_solicitud == 2 || forma_pago.lapos_integrado == 1 || !editar || reutilizacion"
                  />
                </v-col>
                <template v-if="forma_pago.imei_cotizado == 1">
                  <v-col cols="6" sm="3" md="3" class="py-0">
                    <v-text-field
                      v-model.trim="datos.imei_cotizado"
                      label="IMEI"
                      :readonly="!editar"
                      :filled="!editar"
                      :rules="forma_pago.imei_cotizado == 1 ? [rules.required] : []"
                      validate-on-blur
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" sm="3" md="2" class="py-0">
                    <v-text-field
                      v-model="datos.descuento"
                      label="Descuento"
                      type="number"
                      suffix="%"
                      :rules="forma_pago.imei_cotizado == 1 ? [rules.required] : []"
                      validate-on-blur
                      outlined
                      readonly
                      filled
                      dense
                    ></v-text-field>
                  </v-col>
                </template>
                <v-col v-if="forma_pago.cod_autoriz == 1" cols="6" sm="3" class="py-0">
                  <v-text-field
                    v-model.trim="datos.codigo_autorizacion"
                    :label="forma_pago.financiera == 1 ? 'N° Documento' : 'Código autorización'"
                    :readonly="forma_pago.estado_solicitud == 2 || forma_pago.lapos_integrado == 1 || !editar || reutilizacion"
                    :filled="forma_pago.estado_solicitud == 2 || forma_pago.lapos_integrado == 1 || !editar || reutilizacion"
                    :rules="!(forma_pago.estado_solicitud == 2 || forma_pago.lapos_integrado == 1 || !editar || reutilizacion) ? [rules.required] : []"
                    validate-on-blur
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <template v-if="forma_pago.cupon_lote == 1">
                  <v-col cols="6" sm="3" md="2" class="py-0">
                    <v-text-field
                      v-model.trim="datos.cupon"
                      label="Cupón"
                      :readonly="forma_pago.estado_solicitud == 2 || forma_pago.lapos_integrado == 1 || !editar || reutilizacion"
                      :filled="forma_pago.estado_solicitud == 2 || forma_pago.lapos_integrado == 1 || !editar || reutilizacion"
                      :rules="!(forma_pago.estado_solicitud == 2 || forma_pago.lapos_integrado == 1 || !editar || reutilizacion) ? [rules.required] : []"
                      validate-on-blur
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" sm="3" md="2" class="py-0">
                    <v-text-field
                      v-model.trim="datos.lote"
                      label="Lote"
                      :readonly="forma_pago.estado_solicitud == 2 || forma_pago.lapos_integrado == 1 || !editar || reutilizacion"
                      :filled="forma_pago.estado_solicitud == 2 || forma_pago.lapos_integrado == 1 || !editar || reutilizacion"
                      :rules="!(forma_pago.estado_solicitud == 2 || forma_pago.lapos_integrado == 1 || !editar || reutilizacion) ? [rules.required] : []"
                      validate-on-blur
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </template>
                <template v-if="forma_pago.financiera == 1">
                  <v-col cols="6" sm="3" class="py-0">
                    <v-text-field
                      v-model.trim="datos.numero_prestamo"
                      label="N° Préstamo"
                      :readonly="!editar"
                      :filled="!editar"
                      :rules="editar ? [rules.required] : []"
                      validate-on-blur
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="3" class="py-0 mt-n3 mt-md-n5">
                    <span class="mt-1 mr-3">Tipo de Legajo</span>
                    <v-radio-group
                      v-model="datos.legajo_fisico"
                      class="mt-0"
                      :disabled="!editar"
                      :rules="editar ? [rules.required_zero] : []"
                      validate-on-blur
                      dense
                      row
                    >
                      <v-radio
                        label="Físico"
                        :value="1"
                      ></v-radio>
                      <v-radio
                        label="Virtual"
                        :value="0"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </template>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
        <v-scale-transition>
          <v-alert
            v-if="(((p_fp.cobro_online == 1 || p_fp.lapos_integrado == 1) && p_fp.error_id != 0) ||
                    (p_fp.lapos_integrado == 1 && p_fp.estado_solicitud != 2 && p_fp.estado_solicitud != 4 && p_fp.id_solicitud != null)) && !cargando_error"
            border="left"
            type="error"
          >
              {{ p_fp.solicitud_error }}
          </v-alert>
        </v-scale-transition>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          :color="editar ? 'error' : 'secondary'"
          class="mr-2"
          @click="dialog = false"
        >
          {{ editar ? 'Cancelar' : 'Vovler' }}
        </v-btn>
        <v-btn
          v-if="editar"
          color="success"
          @click="confirmar()"
        >
          <v-icon small left>fas fa-check</v-icon>
          Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>      
  </v-dialog>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'
import { roundNumberTF, REGEX_VENC_TAR } from '../../../util/utils'
import FechaPicker from '../../util/FechaPicker.vue'
import TextFieldMoney from '../../util/TextFieldMoney.vue'

export default {
  data () {
    return {
      cbu: null,
      banco: null,
      cc_mask: '',
      cc_digits: '',
      cargando_error: true,
      reutilizacion: false,
      ipg_trans_id: null,
      datos: {},
      rules: {
        required: value => !!value || 'Campo requerido',
        tarjeta: value => (value ? value.replace('-', '').length : 0) > 14 || 'Número no válido',
        vencimiento: value => {
          return REGEX_VENC_TAR.test(value) || 'Fecha inválida'
        },
        required_zero: value => {
          if (value == null || value == undefined) {
            return 'Campo requerido'
          } else {
            return true
          }
        }
      }
    }
  },
  props: {
    value: Boolean,
    p_fp: Object,
    p_ptovta: Object,
    p_init: Object,
    p_formas_pago_group: Array,
    editar: Boolean
  },
  computed: {
    ...mapState('tarjetas', ['tarjetas']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    forma_pago: {
      get () {
        return this.p_fp
      },
      set (value) {
        this.$emit('update:p_fp', value)
      }
    },
    tipos_movimiento: {
      get () {
        return this.p_init.tipos_mov
      }
    },
    all_cuentas: {
      get () {
        return this.p_init.cuentas
      }
    },
    bancos_pais: {
      get () {
        return this.p_init.bancos_pais
      }
    },
    bancos: {
      get () {
        return this.p_init.bancos
      }
    }
  },
  components: {
    FechaPicker,
    TextFieldMoney
  },
  watch: {
    async dialog (val) {
      if (val) {
        if (Object.keys(this.forma_pago).length > 0 && this.forma_pago.hasOwnProperty('datos')) {
          // si tiene cobro transaccion y no tiene id significa q es una reutilizacion
          if (this.forma_pago.cobro_transaccion && this.forma_pago.id == null) {
            this.ipg_trans_id = this.forma_pago.cobro_transaccion
            this.reutilizacion = true
          }
          this.banco = JSON.parse(JSON.stringify(this.forma_pago.banco))
          this.datos = JSON.parse(JSON.stringify(this.forma_pago.datos))
          this.datos.banco_fecha = this.datos.banco_fecha ? this.datos.banco_fecha : moment().format('DD/MM/YYYY')
          this.datos.fecha_cobro = this.datos.fecha_cobro ? this.datos.fecha_cobro : moment().format('DD/MM/YYYY')
          this.formatMask()
          this.setCBU()
        }
        this.cargando_error = true
        await new Promise(resolve => setTimeout(resolve, 1))
        this.cargando_error = false
      } else {
        this.cbu = null
        this.banco = null
        this.datos = {}
        this.cc_mask = ''
        this.cc_digits = ''
        this.cargando_error = true
        this.reutilizacion = false
        this.ipg_trans_id = null
        this.$refs.formBanco.resetValidation()
        this.$refs.formTarjeta.resetValidation()
        this.$refs.formCobro.resetValidation()
        this.$refs.formCP.resetValidation()
      }
    }
  },
  methods: {
    limpiarReutilizacion () {
      if (!this.reutilizacion) {
        this.ipg_trans_id = null
        // si tiene cobro transaccion y no tiene id significa q es una reutilizacion
        if (Object.keys(this.forma_pago).length > 0 && this.forma_pago.cobro_transaccion && this.forma_pago.id == null) {
          // limpio el valor pq ya no lo quiere reutilizar u.u
          this.forma_pago.completa = false
          this.forma_pago.cobro_transaccion = null
        }
      }
    },
    async getCobroLibre () {
      if (!this.ipg_trans_id) return

      let ya_existe = this.p_formas_pago_group.find(fpg => fpg.cobro_transaccion == this.ipg_trans_id)
      if (ya_existe) {
        this.$store.dispatch('show_snackbar', {
          text: `La transacción Nº ${this.ipg_trans_id} ya se encuentra en una forma de pago recientemente agregada`,
          color: 'info'
        })
        this.ipg_trans_id = null
        this.forma_pago.completa = false
        this.forma_pago.cobro_transaccion = null
        return
      }

      this.$store.state.loading_texto = 'Validando transacción...'
      this.$store.state.loading_ultra = true
      let result = await this.$store.dispatch('ventas/validar_transaccion', {
        trans_id: this.ipg_trans_id,
        empresa: this.p_ptovta.empresa
      })
      this.$store.state.loading_ultra = false

      if (result.exito != 1) {
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'error'
        })
        this.ipg_trans_id = null
        this.forma_pago.completa = false
        this.forma_pago.cobro_transaccion = null
        return
      }

      // seteamos los datos en la fp solo si el saldo lo permite xd
      if (roundNumberTF(this.forma_pago.total) > roundNumberTF(result.data.saldo)) {
        this.$store.dispatch('show_snackbar', {
          text: `La Transacción Nº ${this.ipg_trans_id} posee diferencias de importe con la forma de pago seleccionada`,
          color: 'info'
        })
        this.ipg_trans_id = null
        this.forma_pago.completa = false
        this.forma_pago.cobro_transaccion = null
        return
      }

      const datos = result.data
      this.forma_pago.completa = true
      this.forma_pago.cobro_transaccion = datos.cobro_transaccion
      this.datos.tarjeta_numero = datos.tarjeta_numero
      this.datos.fecha_cobro = datos.fecha
      this.datos.codigo_autorizacion = datos.cod_autoriz
      this.datos.cupon = datos.cupon
      this.datos.lote = datos.lote
      this.datos.cliente = true
      this.datos.terceros = false

      this.forma_pago.datos = JSON.parse(JSON.stringify(this.datos))
      this.dialog = false
      this.$store.dispatch('show_snackbar', {
        text: `Transacción Nº ${datos.cobro_transaccion} verificada correctamente`,
        color: 'success'
      })
    },
    async confirmar () {
      let completo = false

      // si tiene tildado reutilizar se lo destildo >:v
      if (this.reutilizacion) {
        this.reutilizacion = false
        this.forma_pago.cobro_transaccion = null
        await new Promise(resolve => setTimeout(resolve, 1))
      }

      // transferencias etc
      if (this.forma_pago.solicita_banco == 1) {
        if (!this.datos.banco_fecha) {
          return this.$store.dispatch('show_snackbar', {
            text: 'Seleccione la fecha del movimiento',
            color: 'orange'
          })
        }
        completo = this.$refs.formBanco.validate()
      }

      // cobro online
      if (this.forma_pago.estado_solicitud != 2 && this.forma_pago.cobro_online == 1) {
        completo = this.$refs.formCobro.validate()
      }

      // datos del cliente (lapos int y desint)
      if ((this.forma_pago.estado_solicitud == 2 || this.forma_pago.estado_solicitud == 0 || this.forma_pago.lapos_integrado == 1) && this.forma_pago.solicita_banco != 1) {
        if (!this.datos.terceros && !this.datos.cliente) {
          return this.$store.dispatch('show_snackbar', {
            text: 'Indique si la tarjeta pertenece al cliente o a un tercero',
            color: 'orange'
          })
        }
        completo = this.$refs.formTarjeta.validate()

        // cupon de pago y num tarjeta (solo desint)
        if (this.forma_pago.lapos_integrado != 1) {
          if (!this.datos.fecha_cobro) {
            return this.$store.dispatch('show_snackbar', {
              text: 'Seleccione la fecha del cupón',
              color: 'orange'
            })
          }
          completo = this.$refs.formCP.validate() && this.$refs.formTarjeta.validate()
        }
      }

      if (completo) {
        this.forma_pago.completa = true
        this.forma_pago.banco = JSON.parse(JSON.stringify(this.banco))
        this.forma_pago.datos = JSON.parse(JSON.stringify(this.datos))
        this.dialog = false
      } else {
        this.$store.dispatch('show_snackbar', {
          text: 'Complete los campos requeridos',
          color: 'orange'
        })
      }
    },
    cadenaVacia (cadena) {
      if (cadena == null || cadena == undefined || cadena.toString().length == 0) return true
      else return false
    },
    setCuenta () {
      let banco = this.bancos.find(ba => ba.codigo == this.datos.banco_codigo)
      if (banco) {
        let cuentas = this.all_cuentas.filter(cu => cu.inhabilitada != 1 && cu.banco_codigo == banco.codigo && cu.empresa_codigo == this.p_ptovta.empresa)
        if (cuentas.length == 1) {
          this.datos.banco_cuenta = cuentas[0].codigo
          this.cbu = cuentas[0].cbu
        }
      }
    },
    setCBU () {
      let cuenta = this.all_cuentas.find(cu => cu.codigo == this.datos.banco_cuenta)
      this.cbu = cuenta ? cuenta.cbu : null
    },
    formatMask () {
      if (isNaN(this.forma_pago.cantidad_digitos)) return
      //FORMO LA MASCARA CON LA CANTIDAD DE DIGITOS DE LA TARJETA
      const digitos = parseInt(this.forma_pago.cantidad_digitos)
      for (let i = 0; i < digitos; i++) {
        this.cc_digits += '#'
      }
      switch (digitos) {
        case 14:
          // es diners
          this.cc_mask = '####-######-####'
          break
        case 15:
          // es amex
          this.cc_mask = '####-######-#####'
          break
        case 17:
          // es platino/titanio
          this.cc_mask = '######-######-#####'
          break
        case 19:
          // es maestro
          this.cc_mask = '#######-#####-#######'
          break
        default:
          let div = parseInt(digitos / 4)
          this.cc_mask = '####'
          for (let i = 0; i < div - 1; i++) {
            this.cc_mask += '-####'
          }
          if(digitos != div * 4) {
            for (let i = 0; i < div; i++) {
              this.cc_mask += '#'
            }
          }
          break
      }
    }
  }
}
</script>