<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <!-- FILTROS -->
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-btn
            style="position: absolute; top: -15px; right: 55px; z-index: 1"
            color="success"
            title="Cargar cierre de lote"
            small
            fab
            @click="dialog_nuevo = true"
          >
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar()">
              <v-row>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Sucursal
                  <v-autocomplete
                    v-model="filtro.sucursal"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="sucursales"
                    hide-details
                    outlined
                    dense
                    @change="filtro.local = null; filtro.terminal = null; terminales = []"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="4" xl="3" class="py-1">
                  Local
                  <v-autocomplete
                    v-model="filtro.local"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="locales.filter(loc => loc.sucursal == filtro.sucursal && loc.empresa == 2)"
                    hide-details
                    outlined
                    dense
                    @change="filtro.terminal = null; get_terminales()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Terminal
                  <v-autocomplete
                    v-model="filtro.terminal"
                    item-text="nombre"
                    item-value="numero"
                    tabindex="1"
                    :items="terminales"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Fecha desde
                  <FechaPicker
                    v-model="filtro.desde"
                    hide-details
                  />
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Fecha hasta
                  <FechaPicker
                    v-model="filtro.hasta"
                    hide-details
                  />
                </v-col>
                <v-col cols="12" sm="6" md="2" class="d-flex align-center mt-sm-4 mt-2 pb-sm-3 pb-0">
                  <v-btn
                    color="blue darken-2"
                    :disabled="filtro.local == null || terminales.length == 0"
                    :dark="!(filtro.local == null || terminales.length == 0)"
                    :small="$vuetify.breakpoint.smAndDown"
                    @click="dialog_modal = true"
                  >
                    <v-icon small left>far fa-stop-circle</v-icon>
                    Enviar/refrescar cierres
                  </v-btn>
                </v-col>
                <BtnFiltro
                  :loading="load"
                  @clear="limpiar()"
                />
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- DATA TABLE -->
      <v-data-table
        class="cebra elevation-2 mt-2"
        sort-by="fecha_envio"
        :headers="headers"
        :items="cierres"
        :search="search"
        :loading="load"
        :footer-props="{'items-per-page-options':[5, 10, 15, 100]}"
        sort-desc
        dense
      >
        <template v-slot:top>
          <v-row class="d-flex justify-end align-center pa-2" no-gutters>
            <v-col cols="6" sm="3" md="2">
              <SearchDataTable
                v-model="search"
              />
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.terminal`]="{ item }">
          {{ item.integrada == 1 ? item.terminal : `${item.terminal} (desint.)` }}
        </template>
        <template v-slot:[`item.fecha_cierre`]="{ item }">
          {{ item.fecha_cierre ? (
              item.tipo_cierre == 2 ? moment(item.fecha_cierre).format('DD/MM/YY') : moment(item.fecha_cierre).format('DD/MM/YY [-] HH:mm')
            ) : '' }}
        </template>
        <template v-slot:[`item.fecha_envio`]="{ item }">
          {{ item.fecha_envio ? moment(item.fecha_envio).format('DD/MM/YY [-] HH:mm') : '' }}
        </template>
        <template v-slot:[`item.estado_nombre`]="{ item }">
          <v-chip :color="item.estado_color" small>
            {{ item.estado_nombre }}
          </v-chip>
        </template>
        <template v-slot:[`item.tipo_cierre`]="{ value }">
          {{ value == 2 ? 'MANUAL' : 'API' }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            v-if="item.estado == 3"
            :color="item.tiene_cupon == 1 ? 'success'  : 'error'"
            :title="item.tiene_cupon == 1 ? 'Ver imagen'  : 'Subir imagen'"
            class="mr-2"
            small
            @click="item.tiene_cupon == 1 ? ver_cupon(item) : subir_cupon(item)"
          >
            {{ item.tiene_cupon == 1 ? 'fas fa-cloud-download-alt' : 'fas fa-cloud-upload-alt' }}
          </v-icon>
          <BtnConfirmar
            v-if="item.tiene_cupon == 1 && permiso_cupon"
            clase="mr-2"
            color="error"
            icono="fas fa-times-circle"
            title="Eliminar imagen del cierre de lote"
            :texto="`¿Desea <strong>eliminar la imagen</strong> del cierre del lote ${item.lote} de la teminal ${item.terminal}?`"
            :icon_button="true"
            :small="true"
            @action="eliminar_cupon(item)"
          />
          <v-icon
            v-if="item.mensaje != null"
            color="orange"
            class="mr-2"
            title="Ver mensaje"
            small
            @click="$swal.fire({
              icon: 'info',
              title: item.mensaje
            })"
          >
            fas fa-info-circle
          </v-icon>
          <v-icon
            v-if="item.estado == 1 || item.estado == 2"
            color="info"
            title="Refrescar estado"
            class="mr-2"
            small
            @click="sincronizar(item)"
          >
            fas fa-sync
          </v-icon>
        </template>
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
    </v-col>
    <!-- COMPONENTES -->
    <ModalCierreLote
      v-model="dialog_modal"
      :ptovta="filtro.local"
      :ptovta_nombre="locales.find(loc => loc.id == filtro.local) ? locales.find(loc => loc.id == filtro.local).nombre : ''"
    />
    <NuevoCierreLote
      v-model="dialog_nuevo"
    />
    <ImgViewer
      v-model="dialog_img"
      :img="img"
      :nombre="nombre_modal"
    />
    <!-- modal subir archivo -->
    <v-dialog
      v-model="dialog"
      width="800"
      scrollable
    >
      <v-card>
        <v-card-title class="pb-0">
          Subir imagen: Terminal {{ nombre_modal }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="dialog = false"
          >
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-0">
          <FileDropzone
            :key="refrescar"
            :multiple="false"
            :tipos="['.png', '.jpg', '.webp']"
            :max_size="6"
            :ruta="ruta_archivo"
            :nombre="nombre_archivo"
            :alerta="true"
            :accion="true"
            :raiz="1"
            :tabla="7"
            :id="id_cierre"
            @action="set_cupon"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { tipos_archivos, getExtensionFileName } from '../../util/utils'
import BtnFiltro from '../../components/util/BtnFiltro'
import BtnConfirmar from '../../components/util/BtnConfirmar'
import FechaPicker from '../../components/util/FechaPicker'
import SearchDataTable from '../../components/util/SearchDataTable'
import ModalCierreLote from '../../components/ventas/ModalCierreLote'
import NuevoCierreLote from '../../components/ventas/NuevoCierreLote'
import FileDropzone from '../../components/util/FileDropzone'
import ImgViewer from '../../components/util/ImgViewer'
import moment from 'moment'

export default {
  data () {
    return {
      moment: moment,
      panel: 0,
      refrescar: 0,
      load: false,
      dialog: false,
      dialog_img: false,
      dialog_nuevo: false,
      dialog_modal: false,
      permiso_cupon: false,
      nombre_modal: '',
      ruta_archivo: '',
      nombre_archivo: '',
      img: '',
      id_cierre: null,
      search: '',
      headers: [
        { text: 'Terminal', value: 'terminal' },
        { text: 'Lote', value: 'lote' },
        { text: 'Fecha cierre', value: 'fecha_cierre' },
        { text: 'Fecha envio', value: 'fecha_envio' },
        { text: 'Usuario log', value: 'usuario_log' },
        { text: 'Método', value: 'tipo_cierre' },
        { text: 'Estado', value: 'estado_nombre' },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false }
      ],
      cierres: [],
      terminales: [],
      filtro_copy: {},
      filtro: {
        sucursal: null,
        local: null,
        terminal: null,
        desde: moment(new Date()).format('DD/MM/YYYY'),
        hasta: null
      }
    }
  },
  async created () {
    // si tiene solo 1 sucursal la selecciono
    if (this.una_sucursal) {
      this.filtro.sucursal = this.sucursales[0].id
    }
    // obtengo los datos necesarios
    this.$store.state.loading = true
    this.permiso_cupon = await this.$store.dispatch('laposIntegrado/get_permiso_cupon')
    this.$store.state.loading = false
  },
  computed: {
    ...mapState(['sucursales', 'locales']),
    ...mapGetters(['una_sucursal'])
  },
  components: {
    BtnFiltro,
    BtnConfirmar,
    FechaPicker,
    SearchDataTable,
    ModalCierreLote,
    NuevoCierreLote,
    FileDropzone,
    ImgViewer
  },
  watch: {
    dialog (val) {
      val || this.refrescar ++
    }
  },
  methods: {
    async sincronizar (item) {
      this.$store.state.loading = true
      let result_sync = await this.$store.dispatch('laposIntegrado/actualizar_cierre_lote', item.terminal)
      this.$store.state.loading = false

      // si actualizo correctamente, vuelvo a traer los datos
      if (result_sync.exito == 1) {
        await this.buscar(1)
      }
      else {
        this.$store.dispatch('show_snackbar', {
          text: result_sync.message,
          color: 'error'
        })
      }
    },
    async eliminar_cupon (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('delete_file', {
        path: `/cupones_cierre_lote/${item.terminal}/${item.archivo_nombre}`,
        raiz: 1,
        tabla: 7,
        id: item.id
      })
        .then((res) => {
          delete item.imagen
          item.tiene_cupon = 0
          item.archivo_nombre = null
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async ver_cupon (item) {
      if (!item.hasOwnProperty('imagen')) {
        // obtengo la imagen
        this.$store.state.loading = true
        await this.$store.dispatch('get_base64', `/jarvis_2.0/cupones_cierre_lote/${item.terminal}/${item.archivo_nombre}`)
          .then((res) => {
            item.imagen = {
              base: res.base,
              tipo: getExtensionFileName(item.archivo_nombre)
            }
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
        this.$store.state.loading = false
      }
      if (item.hasOwnProperty('imagen')) {
        this.img = `data:${tipos_archivos.find(a => a.extension == item.imagen.tipo).mime};base64,${item.imagen.base}`
        this.nombre_modal = `Imagen del lote ${item.lote} - Terminal ${item.terminal}`
        this.dialog_img = true
      }
    },
    set_cupon (item) {
      let cierre = this.cierres.find(cl => cl.id == this.id_cierre)
      if (cierre) {
        cierre.archivo_nombre = item.name
        cierre.tiene_cupon = 1
        cierre.imagen = {
          base: item.file,
          tipo: item.type
        }
      }
    },
    subir_cupon (item) {
      this.dialog = true
      this.nombre_modal = `${item.terminal} - Lote ${item.lote}`
      this.ruta_archivo = '/cupones_cierre_lote/' + item.terminal
      this.nombre_archivo = `${item.id}`
      this.id_cierre = item.id
    },
    async buscar (filtrar_copia) {

      if (filtrar_copia == 1) {
        this.$store.state.loading = true
        this.filtro = await JSON.parse(JSON.stringify(this.filtro_copy))
        await this.get_terminales()
        this.$store.state.loading = false
      }

      if ((!this.filtro.local || !this.filtro.desde) && !this.filtro.terminal) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Debe seleccionar un local o una terminal',
          color: 'orange'
        })
      }
      this.load = true
      this.search = ''
      this.cierres = []
      await this.$store.dispatch('laposIntegrado/get_cierres_lote', this.filtro)
        .then((res) => {
          this.filtro_copy = JSON.parse(JSON.stringify(this.filtro))
          this.cierres = res.data
        })
        .catch(error => {
          this.filtro_copy = {}
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.load = false
    },
    async get_terminales () {
      this.terminales = []
      if (this.filtro.local) {
        this.$store.state.loading = true
        await this.$store.dispatch('localesStore/getTerminales', this.filtro.local)
          .then((res) => {
            for (const terminal of res.data) {
              if (terminal.activa) {
                this.terminales.push({
                  id: terminal.id,
                  numero: terminal.numero,
                  nombre: terminal.integrada ? terminal.numero.toString() : `${terminal.numero} (desint.)`,
                  integrada: terminal.integrada ? 1 : 0
                })
              }
            }
            this.terminales_copy = JSON.parse(JSON.stringify(this.terminales))
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
        this.$store.state.loading = false
      }
    },
    limpiar () {
      this.terminales = []
      this.filtro = {
        sucursal: null,
        local: null,
        terminal: null,
        desde: moment(new Date()).format('DD/MM/YYYY'),
        hasta: null
      }
    }
  }
}
</script>