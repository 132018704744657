<template>
  <v-dialog
    v-model="dialog"
    width="400"
    scrollable
  >
    <v-card>
      <v-card-title style="word-break: normal;">
        {{ nueva ? 'Nueva' : 'Editar' }} Gift Card
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pt-4">
        <v-row>
          <v-col cols="6">
            <text-field-money
              v-model="monto"
              label="Monto"
              v-bind:properties="{
                prefix: '$',
                'hide-details': true
              }"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model.trim="dni"
              label="DNI"
              type="number"
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model.trim="nombre"
              label="Apellido y nombre"
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="error"
          class="mr-2"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="success"
          @click="confirmar()"
        >
          <v-icon small left>fas fa-check</v-icon>
          Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { format_money_round } from '../../../util/utils'
import TextFieldMoney from '../../util/TextFieldMoney.vue'

export default {
  data () {
    return {
      nueva: false,
      monto: null,
      nombre: '',
      dni: ''
    }
  },
  props: {
    value: Boolean,
    p_gf: Object
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    TextFieldMoney
  },
  watch: {
    dialog (val) {
      if (val) {
        if (Object.keys(this.p_gf).length == 0) {
          this.nueva = true
        } else {
          this.monto = JSON.parse(JSON.stringify(this.p_gf.precio_lista))
          this.nombre = JSON.parse(JSON.stringify(this.p_gf.titular))
          this.dni = JSON.parse(JSON.stringify(this.p_gf.dni))
        }
      } else {
        this.nueva = false
        this.monto = null
        this.nombre = ''
        this.dni = ''
      }
    }
  },
  methods: {
    async confirmar () {
      if (this.monto == null || parseFloat(this.monto) <= 0) {
        return this.$store.dispatch('show_snackbar', {
          text: 'El monto debe ser mayor a 0',
          color: 'orange'
        })
      }
      if (parseFloat(this.monto) % 1 != 0) {
        return this.$store.dispatch('show_snackbar', {
          text: 'El monto debe ser un número entero',
          color: 'orange'
        })
      }
      let datos = {
        precio: this.monto,
        nombre: this.nombre,
        dni: this.dni
      }
      if (this.nueva) {
        let modal = await this.$swal.fire({
          icon: 'question',
          html: `<span style="font-size: 20px">¿Desea agregar una Gift Card por <strong>${format_money_round(this.monto)}</strong> al detalle de artículos?</span>`,
          width: '450px',
          confirmButtonText: 'Agregar',
          cancelButtonText: 'Cancelar',
          showCancelButton: true
        })
        if (!modal.isConfirmed) return
        this.$emit('nueva', datos)
      } else {
        this.$emit('actualizar', datos)
        this.dialog = false
      }
    }
  }
}
</script>