<template>
  <div>
    <v-row class="d-flex justify-center mt-0">
      <v-col cols="12">
        <v-expansion-panels v-model="panel">
          <v-expansion-panel readonly>
            <!-- Titulo -->
            <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
              <div>
                <v-icon class="mb-1" left>fas fa-filter</v-icon>
                Gestión Stock
              </div>
              <template v-slot:actions>
                <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                  <v-icon>fas fa-chevron-down</v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>
            <!-- Filtros -->
            <v-expansion-panel-content class="pt-1">
              <v-form @submit.prevent="buscar">
                <!-- Entidad y Origen -->
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" sm="6" md="6" class="py-1">
                    Distribución entre
                    <v-autocomplete
                      v-model="selectedEntidad"
                      item-text="entidad_nombre"
                      :items="entidades"
                      hide-details
                      outlined
                      dense
                      return-object
                      clearable
                      @change="getEntidades()"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="py-1">
                    Origen
                    <v-autocomplete
                      v-model="selectedOrigen"
                      item-text="origen_nombre"
                      :items="origenes"
                      hide-details
                      outlined
                      dense
                      return-object
                      clearable
                      @change="getSucursalesCercanas()"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <!-- Rubro, Categoría, Periodo, Índice y Artículo -->
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" sm="3" md="3" class="py-1">
                    Rubro
                    <v-autocomplete
                      v-model="selectedRubro"
                      item-text="rubro_nombre"
                      :items="rubros"
                      hide-details
                      outlined
                      dense
                      return-object
                      clearable
                      @change="setCategorias()"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="py-1">
                    Categoría
                    <v-autocomplete
                      v-model="selectedCateg"
                      item-text="categoria_nombre"
                      :items="categorias"
                      hide-details
                      outlined
                      dense
                      return-object
                      clearable
                      @change="verificarCategoria()"
                      :disabled="selectedOrigen == null || selectedOrigen == undefined || Object.keys(selectedOrigen).length == 0"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="2" md="2" class="py-1">
                    Periodo
                    <v-autocomplete
                      v-model="selectedPeriodo"
                      item-text="periodo_nombre"
                      :items="periodos"
                      hide-details
                      outlined
                      dense
                      return-object
                      clearable
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="2" md="2" class="py-1">
                    Índice Proyectado
                    <v-text-field
                      v-model="indice"
                      type="number"
                      outlined
                      dense
                      hide-details
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2" md="2" class="py-1">
                    Código Artículo
                    <v-text-field
                      v-model="articulo"
                      type="number"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
                <!-- Botones -->
                <v-row :no-gutters="$vuetify.breakpoint.xs" class="mt-0">
                  <v-col cols="12" class="pt-0">
                    <BtnFiltro
                      :loading="load"
                      @clear="limpiar()"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- Listado de Artículos -->
        <v-row :no-gutters="$vuetify.breakpoint.xs" v-if="generaVista == false">
          <v-col cols="12">
            <v-data-table
              class="cebra elevation-2 mt-2"
              :headers="selectedEntidad.entidad_id != 2 ? headers : headerss"
              :items="articulos"
              item-key="articulo_codigo"
              :loading="load"
              dense
              :search="search"
              single-expand
              :expanded.sync="expanded"
              @click:row="rowClick"
              :sort-by.sync="sortBy"
              sort-desc
            >
              <!-- Buscador en el datatable -->
              <template v-slot:top>
                <v-row class="d-flex justify-end pa-2" no-gutters>
                  <v-col cols="6" sm="3">
                    <SearchDataTable
                      v-model="search"
                    />
                  </v-col>
                </v-row>
              </template>
              <!-- Msj que se mostrara si no existen resultados -->
              <template v-slot:no-data>
                <v-alert
                  class="mx-auto mt-4"
                  max-width="400"
                  type="warning"
                  border="left"
                  dense
                  text
                >
                  Sin artículos para mostrar.
                </v-alert>
              </template>
              <!-- Contenido de la Fila expandida -->
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <v-simple-table
                    class="ma-4"
                    dense
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Destino</th>
                          <th class="text-center">Stock Actual</th>
                          <th class="text-center">Ventas</th>
                          <th class="text-center">A Reasignar</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(dest, index) in item.destino"
                          :key="index"
                        >
                          <td class="text-left">{{ dest.deposito_nombre }}</td>
                          <td class="text-center">{{ dest.cantidad }}</td>
                          <td class="text-center">{{ dest.ventas }}</td>
                          <td class="text-center"><v-text-field v-model="dest.asignado" type="number" outlined dense style="font-weight: bold;" hide-details @blur="controlCantidad(dest)"></v-text-field></td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <v-alert
                    class="mx-auto mt-4"
                    max-width="500"
                    type="warning"
                    border="left"
                    dense
                    text
                    v-if="!item.if_destino"
                  >
                    No hay necesidad de distribución para este articulo.
                  </v-alert>
                </td>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row :no-gutters="$vuetify.breakpoint.xs" v-else>
          <v-col cols="12">
            <StockTabsDestinos 
              :destinos="entidadesDestino"
            />
          </v-col>
        </v-row>
        <v-row :no-gutters="$vuetify.breakpoint.xs">
          <v-col cols="12" align="right">
            <v-btn color="info" @click="generarVista()" v-if="generaVista == false" class="ma-2">Generar Visa</v-btn>
            <v-btn color="info" @click="recalcularAReasignar()" v-else class="ma-2">Volver</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BtnFiltro from '../../components/util/BtnFiltro.vue'
import SearchDataTable from '../../components/util/SearchDataTable.vue'
import StockTabsDestinos from '../../components/stock/StockTabsDestinos.vue'
import { objetoNoVacio, roundNumber } from '../../util/utils'
export default {
  data() {
    return {
      panel: 0,
      selectedEntidad: {},
      entidades: [],
      selectedOrigen: {},
      origenes: [],
      selectedRubro: {},
      rubros: [],
      selectedCateg: {},
      categorias: [],
      categoriasTodas: [],
      selectedPeriodo: {},
      periodos: [],
      depositos: [],
      regiones: [],
      sucursales: [],
      entidadesDestino: [],
      entidadesDestinoAux: [],
      indice: '',
      articulo: '',
      headers: [
        { text: 'Código', align: 'center', value: 'articulo_codigo' },
        { text: 'Artículo', align: 'left', value: 'articulo_nombre' },
        { text: 'Stock Actual', align: 'center', value: 'cantidad' },
        { text: 'Ventas', align: 'center', value: 'ventas' },
        { text: 'Cant. a Mover', align: 'center', value: 'cantidad_disponible' }
      ],
      headerss: [
        { text: 'Código', align: 'center', value: 'articulo_codigo' },
        { text: 'Artículo', align: 'left', value: 'articulo_nombre' },
        { text: 'Indice', align: 'left', value: 'indice_calculado' },
        { text: 'Stock Actual', align: 'center', value: 'cantidad' },
        { text: 'Ventas', align: 'center', value: 'ventas' },
        { text: 'Cant. a Mover', align: 'center', value: 'cantidad_disponible' }
      ],
      articulos: [],
      load: false,
      search: '',
      expanded: [],
      sortBy: 'articulo_codigo',
      generaVista: false
    }
  },
  components: {
    BtnFiltro,
    SearchDataTable,
    StockTabsDestinos
  },
  created () {
    this.initForm()
  },
  methods: {
    async initForm(){
      if (localStorage.getItem('stock_categorias') && localStorage.getItem('stock_depositos') && localStorage.getItem('stock_entidades') &&
      localStorage.getItem('stock_periodos') && localStorage.getItem('stock_regiones') && localStorage.getItem('stock_rubros') && 
      JSON.parse(localStorage.getItem('stock_categorias')).length > 0 && JSON.parse(localStorage.getItem('stock_depositos')).length > 0 &&
      JSON.parse(localStorage.getItem('stock_entidades')).length > 0 && JSON.parse(localStorage.getItem('stock_periodos')).length > 0 &&
      JSON.parse(localStorage.getItem('stock_regiones')).length > 0 && JSON.parse(localStorage.getItem('stock_rubros')).length > 0){
        this.entidades = JSON.parse(localStorage.getItem('stock_entidades'))
        this.regiones = JSON.parse(localStorage.getItem('stock_regiones'))
        this.rubros = JSON.parse(localStorage.getItem('stock_rubros'))
        this.categoriasTodas = JSON.parse(localStorage.getItem('stock_categorias'))
        this.periodos = JSON.parse(localStorage.getItem('stock_periodos'))
        this.depositos = JSON.parse(localStorage.getItem('stock_depositos'))
      }else{
        this.$store.state.loading = true
        let initPeticion = await this.$store.dispatch('stock/initGestionStock')
        this.$store.state.loading = false
        if (initPeticion.resultado == 1){
          this.entidades = initPeticion.entidades
          this.regiones = initPeticion.regiones
          this.rubros = initPeticion.rubros
          this.categoriasTodas = initPeticion.categorias
          this.periodos = initPeticion.periodos
          this.depositos = initPeticion.depositos
        }else{
          this.$store.dispatch('show_snackbar', {
            text: initPeticion.msj,
            color: 'error',
          })
        }
      }
    },
    filtrosValidos(){
      if (Object.keys(this.selectedEntidad).length == 0) return {valido: false, msj: 'Debe seleccionar un tipo de distribución.'}
      if (Object.keys(this.selectedOrigen).length == 0) return {valido: false, msj: 'Debe seleccionar un Origen.'}
      if (Object.keys(this.selectedRubro).length == 0) return {valido: false, msj: 'Debe seleccionar un Rubro.'}
      if (Object.keys(this.selectedPeriodo).length == 0) return {valido: false, msj: 'Debe seleccionar un Periodo.'}
      if (this.articulo.toString().length == 0){
        if (Object.keys(this.selectedCateg).length == 0) return {valido: false, msj: 'Debe seleccionar una Categoria.'}
      }
      return {valido: true, msj: 'OK'}
    },
    async buscar(){
      let valido = this.filtrosValidos()
      if (valido.valido == false){
        this.$store.dispatch('show_snackbar', {
          text: valido.msj,
          color: 'info',
        })
        return
      }
      this.articulos = []
      let params = {}
      if (this.selectedEntidad.entidad_id == 1){
        // por sucursal
        params = {
          entidad: this.selectedEntidad.entidad_nombre,
          periodo: this.selectedPeriodo.periodo_nombre,
          sucursal: this.selectedOrigen.origen_codigo,
          rubro: this.selectedRubro.rubro_codigo,
          categoria: Object.keys(this.selectedCateg).length > 0 ? this.selectedCateg.categoria_codigo : 0,
          indice: (this.indice != null && this.indice != undefined && this.indice.toString().length > 0) ? this.indice : 0,
          deposito: 0,
          region: 0,
          articulo: this.articulo.toString().length > 0 ? this.articulo : 0,
          opcion: 1
        }
      }else if (this.selectedEntidad.entidad_id == 2){
        // por region
        params = {
          entidad: this.selectedEntidad.entidad_nombre,
          periodo: this.selectedPeriodo.periodo_nombre,
          sucursal: 0,
          rubro: this.selectedRubro.rubro_codigo,
          categoria: Object.keys(this.selectedCateg).length > 0 ? this.selectedCateg.categoria_codigo : 0,
          indice: (this.indice != null && this.indice != undefined && this.indice.toString().length > 0) ? this.indice : 0,
          deposito: 0,
          region: this.selectedOrigen.origen_nombre,
          articulo: this.articulo.toString().length > 0 ? this.articulo : 0,
          opcion: 2
        }
      }else{
        // por bodega
        params = {
          entidad: this.selectedEntidad.entidad_nombre,
          periodo: this.selectedPeriodo.periodo_nombre,
          sucursal: this.selectedOrigen.sucursal_codigo,
          rubro: this.selectedRubro.rubro_codigo,
          categoria: Object.keys(this.selectedCateg).length > 0 ? this.selectedCateg.categoria_codigo : 0,
          indice: (this.indice != null && this.indice != undefined && this.indice.toString().length > 0) ? this.indice : 0,
          deposito: this.selectedOrigen.origen_codigo,
          region: 0,
          articulo: this.articulo.toString().length > 0 ? this.articulo : 0,
          opcion: 3
        }
      }
      this.$store.state.loading = true
      let detallesPeticion = await this.$store.dispatch('stock/getGestionStockDets', params)
      this.$store.state.loading = false
      if (detallesPeticion.resultado == 0){
        this.$store.dispatch('show_snackbar', {
          text: detallesPeticion.msj,
          color: 'error',
        })
        return
      }
      // exitoso
      this.articulos = detallesPeticion.detalles
    },
    limpiar(){
      this.selectedEntidad = {}
      this.selectedOrigen = {}
      this.origenes = []
      this.selectedRubro = {}
      this.selectedCateg = {}
      this.categorias = []
      this.selectedPeriodo = {}
      this.indice = ''
      this.articulo = ''
      this.entidadesDestino = []
      this.entidadesDestinoAux = []
    },
    getEntidades(){
      if (this.selectedEntidad == null || this.selectedEntidad == undefined || Object.keys(this.selectedEntidad).length == 0){
        this.selectedOrigen = {}
        this.origenes = []
        return
      }
      this.selectedOrigen = {}
      if (this.selectedEntidad.entidad_id == 1){
        this.sucursales = JSON.parse(localStorage.getItem('sucursales'))
        for (let id in this.sucursales){
          this.sucursales[id].origen_codigo = this.sucursales[id].id
          this.sucursales[id].origen_nombre = this.sucursales[id].nombre
        }
        this.origenes = this.sucursales
      }else if (this.selectedEntidad.entidad_id == 2) this.origenes = this.regiones
      else this.origenes = this.depositos
    },
    async getSucursalesCercanas(){
      if (!objetoNoVacio(this.selectedOrigen)) return
      if (objetoNoVacio(this.selectedEntidad) && this.selectedEntidad.entidad_id == 1){
        this.entidadesDestinoAux = []
        this.$store.state.loading = true
        let sucuPeticion = await this.$store.dispatch('stock/getSucursalesCercanas',{
          sucursal_codigo: this.selectedOrigen.origen_codigo
        })
        this.$store.state.loading = false
        if (sucuPeticion.resultado == 1){
          this.entidadesDestinoAux = sucuPeticion.sucursales
        }else{
          this.$store.dispatch('show_snackbar', {
            text: sucuPeticion.msj,
            color: 'error',
          })
        }
        return
      }
      this.setEntidadDestino()
    },
    setCategorias(){
      if (!objetoNoVacio(this.selectedRubro)) return
      this.selectedCateg = {}
      this.categorias = []
      // obtengo las categorias
      this.categorias = this.categoriasTodas.filter(element => element.rubro_codigo == this.selectedRubro.rubro_codigo)
      // indice
      this.indice = this.selectedEntidad.entidad_id != 2 ? this.selectedRubro.ind_rotacion_min : ''
    },
    setEntidadDestino(){
      if (this.selectedEntidad.entidad_id == 3){
        this.entidadesDestinoAux = []
        let dep = this.depositos.filter(element => element.sucursal_codigo == this.selectedOrigen.sucursal_codigo || element.sucursal_asociada == this.selectedOrigen.sucursal_asociada)
        for (let id in dep){
          if (dep[id].deposito_codigo != this.selectedOrigen.origen_codigo){
            let depos = dep[id]
            depos.articulos = []
            this.entidadesDestinoAux.push(depos)
          }
        }
      }else if (this.selectedEntidad.entidad_id == 1) this.getSucursalesCercanas()
      else{
        this.entidadesDestinoAux = []
        this.entidadesDestinoAux = this.regiones.filter(element => element.origen_nombre.toString().toUpperCase().trim() != this.selectedOrigen.origen_nombre.toString().toUpperCase().trim())
      }
    },
    verificarCategoria(){
      if (this.entidadesDestinoAux.length != 0){
        this.entidadesDestinoAux = []
        this.setEntidadDestino()
      }
    },
    setDestinos(destinos){
      let procesados = []
      for (let id in destinos){
        // seteo la suma
        let suma = 0
        // pregunto si ya procesé el artículo
        let yaProcess = procesados.filter(element => element.articulo_codigo == destinos[id].articulo_codigo)
        if (yaProcess.length == 0){
          // lo agrego a los procesados
          procesados.push({articulo_codigo: destinos[id].articulo_codigo})
          // obtengo todos los artículos del mismo código en destinos
          let existe = destinos.filter(element => element.articulo_codigo == destinos[id].articulo_codigo)
          if (existe.length > 0){
            // obtengo la suma del campo vtas_60
            suma = roundNumber(existe.reduce((sum, item) => sum + item.vtas_60, 0), 2)
            // calculo el porcentaje recomendado para cada artículo
            if (suma > 0){
              for (let jd in existe){
                existe[jd].porc_rec = parseInt(existe[jd].vtas_60)/parseInt(suma)
              }
            }
            // busco el artículo en el detalle de artículos
            let art = this.articulos.filter(element => element.articulo_codigo == existe[0].articulo_codigo)
            if (art.length == 1){
              // obtengo la posición dentro del array
              let index = this.articulos.indexOf(art[0])
              let disponible = parseInt(this.articulos[index].cantidad_disponible)
              let detalleDisponible = parseInt(this.articulos[index].cantidad_disponible)
              for (let jd in existe){
                let asignar = parseInt(existe[jd].porc_rec * detalleDisponible)
                let artDisponible = parseInt(existe[jd].cantidad_disponible)
                // si lo que necesito para llegar al indice de rotacion es menor a lo que el porcentaje me recomienda le doy la cantidad necesaria para llegar al indice proyectado
                // si la cantidad a asignar es 0, le asigno la unidad minima
                if (artDisponible < asignar) asignar = artDisponible
                else if (asignar == 0) asignar = 1
                // recomiendo una distribucion inicial
                if (asignar > disponible){
                  // si lo que necesito es mayor a lo que tengo, le asigno todo
                  existe[jd].asignado = disponible
                  disponible = 0
                }else if (asignar <= disponible){
                  // si lo que necesito es menor a lo que tengo, cubro esa cantidad
                  existe[jd].asignado = asignar
                  disponible = disponible - asignar
                }else if (disponible == 0){
                  existe[jd].asignado = 0
                }
                this.articulos[index].destino.push(existe[jd])
              }
              this.articulos[index].if_destino = true
            }
          }
        }
      }
    },
    rowClick (item, row) {
      let selectState = (row.isSelected) ? false : true
      row.select(selectState)
      if (!row.isSelected){
        this.expanded = []
        this.expanded.push(item)
      }else this.expanded = []
      if (this.selectedEntidad.entidad_id == 2) this.indice = item.indice_calculado
      else this.indice = (this.selectedRubro != null && this.selectedRubro != undefined && Object.keys(this.selectedRubro).length > 0) ? this.selectedRubro.ind_rotacion_min : 0
    },
    controlCantidad(item){
      let detalles = this.articulos.filter(element => element.articulo_codigo == item.articulo_codigo)
      if (detalles.length == 1){
        let sum = 0
        let pos = this.articulos.indexOf(detalles[0])
        for (let id in detalles[0].destino){
          // verifico que el valor ingresado sea numerico
          if (isNaN(detalles[0].destino[id].asignado)){
            this.$store.dispatch('show_snackbar', {
              text: 'Valor no válido. Por favor verificar.',
              color: 'warning',
            })
            return
          }
          // tengo algo en el campo asignado?
          if (detalles[0].destino[id].asignado == null || detalles[0].destino[id].asignado == undefined || detalles[0].destino[id].asignado.toString().length == 0){
            detalles[0].destino[id].asignado = 0
            Object.assign(this.articulos[pos], detalles[0])
          }
          // verifico que las cantidades sean <= al disponible por la sucursal/local
          sum = sum + parseInt(detalles[0].destino[id].asignado)
          if (sum > parseInt(this.articulos[pos].cantidad_disponible)){
            this.$store.dispatch('show_snackbar', {
              text: 'Existen diferencias en las cantidades asignadas. Por favor verificar.',
              color: 'warning',
            })
            return
          }
          Object.assign(this.articulos[pos], detalles[0])
        }
      }
    },
    async generarVista(){
      this.generaVista = true
      this.entidadesDestino = []
      if (this.selectedEntidad.entidad_id != 1) this.setEntidadDestino()
      else{
        this.$store.state.loading = true
        let sucuPeticion = await this.$store.dispatch('stock/getSucursalesCercanas',{
          sucursal_codigo: this.selectedOrigen.origen_codigo
        })
        this.$store.state.loading = false
        if (sucuPeticion.resultado == 1){
          this.entidadesDestinoAux = sucuPeticion.sucursales
        }else{
          this.$store.dispatch('show_snackbar', {
            text: sucuPeticion.msj,
            color: 'error',
          })
        }
      }
      // recorremos todos los artículos
      for (let id in this.articulos){
        for (let jd in this.articulos[id].destino){
          if (this.articulos[id].destino[jd].asignado != 0){
            let destino = []
            if (this.selectedEntidad.entidad_id == 3){
              destino = this.entidadesDestinoAux.filter(element => element.origen_codigo == this.articulos[id].destino[jd].deposito_codigo)
            }else if (this.selectedEntidad.entidad_id == 1){
              destino = this.entidadesDestinoAux.filter(element => element.sucursal_codigo == this.articulos[id].destino[jd].deposito_codigo)
            }
            if (destino.length == 1){
              let index = this.entidadesDestinoAux.indexOf(destino[0])
              this.entidadesDestinoAux[index].articulos.push(this.articulos[id].destino[jd])
            }
          }
        }
      }
      if (this.selectedEntidad.entidad_id != 2){
        this.entidadesDestino = []
        for (let id in this.entidadesDestinoAux){
          if (this.entidadesDestinoAux[id].articulos.length != 0) this.entidadesDestino.push(this.entidadesDestinoAux[id])
        }
      }
    },
    recalcularAReasignar(){
      this.generaVista = false
      this.setEntidadDestino()
    }
  },
  watch: {
    selectedEntidad: function(){
      if (this.selectedEntidad == null || this.selectedEntidad == undefined) this.selectedEntidad = {}
      this.indice = ''
      if (objetoNoVacio(this.selectedRubro) && objetoNoVacio(this.selectedEntidad) && this.selectedEntidad.entidad_id != 2) this.indice =  this.selectedRubro.ind_rotacion_min
    },
    selectedOrigen: function(){
      if (this.selectedOrigen == null || this.selectedOrigen == undefined) this.selectedOrigen = {}
      if (!objetoNoVacio(this.selectedOrigen)) this.selectedCateg = {}
    },
    selectedRubro: function(){
      if (this.selectedRubro == null || this.selectedRubro == undefined){
        this.selectedRubro = {}
        this.selectedCateg = {}
        this.categorias = []
        return
      }
      if (!objetoNoVacio(this.selectedRubro)) this.indice = ''
    },
    selectedCateg: function(){
      if (this.selectedCateg == null || this.selectedCateg == undefined) this.selectedCateg = {}
    },
    selectedPeriodo: function(){
      if (this.selectedPeriodo == null || this.selectedPeriodo == undefined) this.selectedPeriodo = {}
    },
    entidadesDestinoAux: function(){
      if (this.entidadesDestinoAux.length > 0){
        if (this.selectedEntidad.entidad_id == 1){
          // sucursal, restablezo los articulos
          for (let id in this.entidadesDestinoAux){
            this.entidadesDestinoAux[id].articulos = []
          }
          for (let id in this.articulos){
            for (let jd in this.articulos[id].destino){
              if (this.articulos[id].destino[jd].asignado != 0){
                let destino = this.entidadesDestinoAux.filter(element => element.sucursal_codigo == this.articulos[id].destino[jd].deposito_codigo)
                if (destino.length > 0){
                  let index = this.entidadesDestinoAux.indexOf(destino[0])
                  this.entidadesDestinoAux[index].articulos.push(this.articulos[id].destino[jd])
                }
              }
            }
          }
          this.entidadesDestino = []
          for (let id in this.entidadesDestinoAux){
            if (this.entidadesDestinoAux[id].articulos.length != 0) this.entidadesDestino.push(this.entidadesDestinoAux[id])
          }
        }else if (this.selectedEntidad.entidad_id == 2){
          // region, restablezo los articulos
          for (let id in this.entidadesDestinoAux){
            this.entidadesDestinoAux[id].articulos = []
          }
          // recorro todos los articulos
          for (let id in this.articulos){
            for (let jd in this.articulos[id].destino){
              if (this.articulos[id].destino[jd].asignado != 0){
                let destino = this.entidadesDestinoAux.filter(element => element.origen_nombre.toString().toUpperCase().trim() == this.articulos[id].destino[jd].deposito_nombre.toString().toUpperCase().trim())
                if (destino.length > 0){
                  let index = this.entidadesDestinoAux.indexOf(destino[0])
                  this.entidadesDestinoAux[index].articulos.push(this.articulos[id].destino[jd])
                }
              }
            }
          }
          this.entidadesDestino = []
          for (let id in this.entidadesDestinoAux){
            if (this.entidadesDestinoAux[id].articulos.length != 0) this.entidadesDestino.push(this.entidadesDestinoAux[id])
          }
        }
      }
    },
    articulos: async function(){
      let articulosDestinos = []
      if (this.articulos.length > 0){
        for (let id in this.articulos){
          Object.defineProperty(this.articulos[id], 'destino', {
            value: [], 
            writable: true, 
            enumerable: true, 
            configurable: true
          })
          Object.defineProperty(this.articulos[id], 'if_disponible', {
            value: false, 
            writable: true, 
            enumerable: true, 
            configurable: true
          })
          Object.defineProperty(this.articulos[id], 'if_destino', {
            value: false, 
            writable: true, 
            enumerable: true, 
            configurable: true
          })
          let indiceRotacion = roundNumber(this.articulos[id].indice_rotacion, 2)
          let indiceMin = roundNumber(this.selectedRubro.ind_rotacion_min, 2)
          let dif = indiceRotacion - indiceMin
          if (dif > 0) articulosDestinos.push({articulo_codigo: this.articulos[id].articulo_codigo, indice_calculado: this.articulos[id].indice_calculado})
        }
      }
      // debo buscar destinos?
      if (articulosDestinos.length > 0){
        let params = {}
        if (this.selectedEntidad.entidad_id == 1){
          // por sucursal
          params = {
            entidad: this.selectedEntidad.entidad_nombre,
            periodo: this.selectedPeriodo.periodo_nombre,
            sucursal: this.selectedOrigen.origen_codigo,
            rubro: this.selectedRubro.rubro_codigo,
            categoria: Object.keys(this.selectedCateg).length > 0 ? this.selectedCateg.categoria_codigo : 0,
            indice: (this.indice != null && this.indice != undefined && this.indice.toString().length > 0) ? this.indice : 0,
            deposito: 0,
            region: 0,
            articulos: articulosDestinos,
            opcion: 1
          }
        }else if(this.selectedEntidad.entidad_id == 2){
          // por region
          params = {
            entidad: this.selectedEntidad.entidad_nombre,
            periodo: this.selectedPeriodo.periodo_nombre,
            sucursal: 0,
            rubro: this.selectedRubro.rubro_codigo,
            categoria: Object.keys(this.selectedCateg).length > 0 ? this.selectedCateg.categoria_codigo : 0,
            indice: (this.indice != null && this.indice != undefined && this.indice.toString().length > 0) ? this.indice : 0,
            deposito: 0,
            region: this.selectedOrigen.origen_nombre,
            articulos: articulosDestinos,
            opcion: 2
          }
        }else{
          // por deposito o local
          params = {
            entidad: this.selectedEntidad.entidad_nombre,
            periodo: this.selectedPeriodo.periodo_nombre,
            sucursal: this.selectedOrigen.sucursal_codigo,
            rubro: this.selectedRubro.rubro_codigo,
            categoria: Object.keys(this.selectedCateg).length > 0 ? this.selectedCateg.categoria_codigo : 0,
            indice: (this.indice != null && this.indice != undefined && this.indice.toString().length > 0) ? this.indice : 0,
            deposito: this.selectedOrigen.origen_codigo,
            region: 0,
            articulos: articulosDestinos,
            opcion: 3
          }
        }
        this.$store.state.loading = true
        let destinoPeticion = await this.$store.dispatch('stock/getGestionStockXArticulo', params)
        this.$store.state.loading = false
        if (destinoPeticion.resultado == 0){
          this.$store.dispatch('show_snackbar', {
            text: destinoPeticion.msj,
            color: 'error',
          })
          return
        }
        // tengo al para recorrer?
        if (destinoPeticion.destinos.length > 0) this.setDestinos(destinoPeticion.destinos)
      }
    }
  },
}
</script>

<style>

</style>