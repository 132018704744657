<template>
  <v-dialog
    v-model="dialog"
    width="700"
    persistent
    scrollable
  >
    <v-card>
      <v-card-title style="word-break: normal;">
        Confirmación de Cobro en LaPos
      </v-card-title>
      <v-divider class="pb-2"></v-divider>
      <v-card-text class="px-0 pb-sm-4 pb-2">
        <v-expand-transition>
          <div v-if="show_alert" class="mx-4">
            <v-alert
              v-model="show_alert"
              style="width: 100%;"
              type="warning"
              color="orange"
              border="left"
              dismissible
              text
            >
              <div class="body-2">
                La actualización terminó de forma exitosa pero hay formas de pago que no coinciden con el estado deseado
              </div>
            </v-alert>
          </div>
        </v-expand-transition>
        <div :class="`text-h6 text-center mb-${$vuetify.breakpoint.xs ? 10 : 2}`">
          {{ titulo }}
        </div>
        <v-data-table
          sort-by="financiacion"
          :items="detalles"
          :headers="headers"
          :loading="load"
          :items-per-page="-1"
          hide-default-footer
          mobile-breakpoint
          dense
        >
          <template v-slot:top>
            <div :class="`text-right mr-${$vuetify.breakpoint.xs ? 6 : 11} mt-n9`">
              <v-icon
                color="info"
                class="mb-2"
                title="Sincronizar pagos"
                :disabled="detalles.length == 0"
                @click="actualizarDetalles(1, 1)"
              >
                fas fa-sync
              </v-icon>
            </div>
          </template>
          <template v-slot:[`item.estado`]="{ item }">
            <v-chip :color="item.estado_cobro_color" small>
              {{ item.estado_cobro_nombre }}
            </v-chip>
          </template>
          <template v-slot:[`item.mensaje`]="{ item }">
            <v-icon
              v-if="item.estado_cobro != 1"
              :color="item.estado ? 'success' : item.estado_cobro == 1 ? 'amber' : 'deep-orange'"
              :title="item.estado ? 'OK' : item.estado_nombre"
            >
              fas fa-{{ item.estado ? 'check' : 'exclamation' }}-{{ item.estado_cobro == 1 ? 'triangle' : 'circle' }}
            </v-icon>
          </template>
          <template v-slot:[`item.total_final`]="{ value }">
            {{ formatMoney(value) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              color="info"
              title="Sincronizar"
              class="mr-2"
              :disabled="lock_btn"
              small
              @click="actualizarDetalle(item)"
            >
              fas fa-sync
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="600"
              type="warning"
              border="left"
              dense
              text
            >
              No se encontraron detalles para procesar
            </v-alert>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-row class="d-flex justify-end pa-3">
          <v-btn
            color="info"
            class="ml-2 mt-sm-0 mt-2"
            :disabled="load || lock_btn || detalles.length == 0"
            @click="actualizarDetalles(1, 1)"
          >
            <v-icon small left>fas fa-sync</v-icon>
            Sincronizar pagos
          </v-btn>
          <v-btn
            color="indigo"
            class="ml-2 mt-sm-0 mt-2"
            :disabled="load || lock_btn || lock_editar"
            :dark="!(load || lock_btn || lock_editar)"
            @click="$emit('editar')"
          >
            <v-icon small left>fas fa-pen</v-icon>
            Editar Venta
          </v-btn>
          <v-btn
            color="error"
            :disabled="load || lock_anular"
            class="ml-2 mt-sm-0 mt-2"
            @click="anularVenta()"
          >
            <v-icon small left>fas fa-ban</v-icon>
            Anular Venta
        </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { format_money } from '../../../util/utils'

export default {
  data () {
    return {
      formatMoney: format_money,
      load: false,
      lock_btn: false,
      lock_anular: false,
      lock_editar: false,
      show_alert: false,
      liberar_todo: 0,
      anular_todo: 0,
      anular: 0,
      titulo: 'Sincronice los Cobros para continuar',
      detalles: [],
      headers: [
        { text: 'Forma pago', value: 'descripcion' },
        { text: 'Importe', value: 'total_final', align: 'end' },
        { text: 'Estado', value: 'estado' },
        { text: '', value: 'mensaje', align: 'right', sortable: false },
        { text: 'Acciones', value: 'actions', align: 'center', sortable: false, filterable: false }
      ]
    }
  },
  props: {
    value: Boolean,
    venta_id: String,
    anulando: Number
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    async dialog (val) {
      if (val) {
        this.load = true
        let result = await this.$store.dispatch('ventas/getDetallesLaPos', this.venta_id)
        this.load = false

        if (result.exito != 1) {
          this.$swal.fire({
            icon: 'error',
            title: result.message
          })
          return
        }

        if (result.size == 0) {
          // si no encontro detalles y esta queriendo anular es pq ya estan anulados
          if (this.anulando == 1) {
            // pero igual porsilas reviso q realmente este todito anulado
            this.$store.state.loading = true
            let result_an = await this.$store.dispatch('ventas/verificar_anulados', this.venta_id)
            this.$store.state.loading = false

            if (result_an.exito != 1) {
              this.$swal.fire({
                icon: 'error',
                title: result_an.message
              })
              return
            }

            this.dialog = false
            this.$emit('anular')
            return
          }
          this.$swal.fire({
            icon: 'info',
            title: 'No se encontraron detalles para procesar'
          })
          return
        }

        // armo el array
        for (const detalle of result.data) {
          let unDetFP = {
            sol_id: detalle.id,
            titular: detalle.titular,
            titular_dni:  detalle.titular_dni,
            descripcion: detalle.descripcion,
            total_final: detalle.total_final,
            estado: false,
            estado_nombre: 'Este detalle está pendiente de sincronización',
            estado_cobro: 1,
            estado_cobro_color: 'info',
            estado_cobro_nombre: 'PENDIENTE',
            cob_tipo_transaccion_id: 0,
            sol_cobro_estado_id: 0,
            actualizar_correcto: 0
          }
          this.detalles.push(unDetFP)
        }

        if (this.anulando == 1) {
          this.anular = 1
          this.anular_todo = 1
          this.actualizarDetalles(2, 1)
        } else {
          this.actualizarDetalles(1, 0)
        }

      } else {
        this.detalles = []
        this.lock_btn = false
        this.lock_anular = false
        this.lock_editar = false
        this.liberar_todo = 0
        this.anular_todo = 0
        this.anular = 0
      }
    }
  },
  methods: {
    async actualizarDetalles (opcion, sincronizar) {
      let error = 0
      let error_estado = 0
      this.lock_btn = false

      // si la opcion es 2 con sincronizar y estan anulando y no hay detalles entonces reviso q todo este anulado
      if (opcion == 2 && sincronizar == 1 && this.detalles.length == 0 && this.anulando == 1) {
        this.$store.state.loading = true
        let result = await this.$store.dispatch('ventas/verificar_anulados', this.venta_id)
        this.$store.state.loading = false

        if (result.exito != 1) {
          this.$swal.fire({
            icon: 'error',
            title: result.message
          })
          return
        }

        this.dialog = false
        this.$emit('anular')
        return
      }

      if (sincronizar == 1) {
        this.$store.state.loading_lps = true
        for (let fp of this.detalles) {
          let result = await this.$store.dispatch('laposIntegrado/actualizar', fp.sol_id)
          if (result.exito == 1) {
            fp.estado = false
            fp.estado_nombre = 'Se actualizó correctamente esta forma de pago pero no se comprobó si fué exitosa aún'
            fp.actualizar_correcto = 1
          } else {
            error++
            fp.estado = false
            fp.estado_nombre = result.message
          }
        }
        this.$store.state.loading_lps = false
      }

      // obtengo los estados de los detalles
      this.$store.state.loading = true
      let resultado = await this.$store.dispatch('ventas/getEstadosLaPos', {
        solicitudes: this.detalles.map(sol => sol.sol_id)
      })
      this.$store.state.loading = false

      if (resultado.exito != 1) {
        this.$swal.fire({
          icon: 'error',
          title: resultado.message
        })
        return
      }

      // si la longitud de los detalles - los estados es distinta entonces algo malio sal
      if (this.detalles.length != resultado.size) {
        this.$swal.fire({
          icon: 'info',
          title: 'Se detectaron inconsistencias en los detalles'
        })
        return
      }

      // actualizo los estados de los detalles
      for (const det_fp of resultado.data) {
        let detalle = this.detalles.filter(det => det.sol_id == det_fp.solicitud_id)
        if (detalle.length == 1) {
          // solo toco el estado si y solo si se actualizo correctamente en lapos integrado, esto es para no sobreescribir
          // el nombre del estado que contiene el error del envio a actualizar de la primera parte
          if (detalle[0].actualizar_correcto == 1 || sincronizar == 0) {
            detalle[0].estado = det_fp.cobro_ok == 1
            detalle[0].estado_nombre = det_fp.error_mensaje
          }

          // actualizo el estado del cobro
          detalle[0].estado_cobro = det_fp.estado_id
          detalle[0].estado_cobro_color = det_fp.color
          detalle[0].estado_cobro_nombre = det_fp.estado_nombre
          detalle[0].cob_tipo_transaccion_id = det_fp.cob_tipo_transaccion_id
          detalle[0].sol_cobro_estado_id = det_fp.sol_cobro_estados_id

          // controlamos si el estado del cobro en oracle es con error, si fuera asi paso a hacer el envio de la solicitud nuevamente, ya que la misma desaparece si se procesa con error
          if ((detalle[0].estado_cobro == 5 || detalle[0].estado_cobro == 1) && opcion == 1 && this.anular != 1 && error == 0) {
            this.$store.state.loading_lps = true
            let result = await this.$store.dispatch('laposIntegrado/enviar_pago', detalle[0].sol_id)
            this.$store.state.loading_lps = false

            if (result.exito != 1) {
              error_estado++
            }
          }

          // mandamos nuevamente la anulacion si el cobro esta aprobado y mandan a sincronizar
          if (detalle[0].estado_cobro == 2 && opcion == 1 && this.anular == 1 && error == 0) {
            this.$store.state.loading_lps = true
            let result = await this.$store.dispatch('laposIntegrado/enviar_anular', detalle[0].sol_id)
            this.$store.state.loading_lps = false

            if (result.exito != 1) {
              error_estado++
            }
          }

          // marcar como anuladas las que estan con error cuando el usuario está anulando
          if (detalle[0].estado_cobro == 5 && (opcion == 1 || opcion == 2) && this.anular == 1 && error == 0) {
            this.$store.state.loading_lps = true
            let result = await this.$store.dispatch('laposIntegrado/set_anular_oracle', detalle[0].sol_id)
            this.$store.state.loading_lps = false

            if (result.exito == 1) {
              // lo marque exitosamente como anulado, por lo tanto marco el nuevo estado aqui en el array
              detalle[0].estado_cobro = 4
              detalle[0].estado_cobro_nombre = 'ANULADO'
              detalle[0].estado = true
              detalle[0].estado_nombre = 'OK'
            } else {
              error_estado++
            }
          }

        } else {
          error_estado++
        }
      }

      // termina de actualizar los estados, paso a controlar que todos esten con estado OK según la acción del usuario
      await this.controlLaposIntegrado()

      // debo pasar a anular?
      if (opcion == 2 || opcion == 3) {
        this.ejecutarAnulaciones(error + error_estado, opcion)
      }
    },
    async actualizarDetalle (item) {
      let error = 0

      this.$store.state.loading_lps = true
      let result = await this.$store.dispatch('laposIntegrado/actualizar', item.sol_id)
      this.$store.state.loading_lps = false

      if (result.exito == 1) {
        item.estado = false
        item.estado_nombre = 'Se actualizó correctamente esta forma de pago pero no se comprobó si fué exitosa aún'
        item.actualizar_correcto = 1
      } else {
        error++
        item.estado = false
        item.estado_nombre = result.message
      }

      // obtengo el estado del detalle
      this.$store.state.loading = true
      let resultado = await this.$store.dispatch('ventas/getEstadosLaPos', { solicitudes: [ item.sol_id ] })
      this.$store.state.loading = false

      if (resultado.exito != 1) {
        this.$swal.fire({
          icon: 'error',
          title: resultado.message
        })
        return
      }

      // me tendria q haber devuelto si o si solo 1 detalle
      if (resultado.size != 1) {
        this.$swal.fire({
          icon: 'info',
          title: `No se encontró el Cobro N° ${item.sol_id}`
        })
        return
      }

      const det_fp = resultado.data[0]

      // solo toco el estado si y solo si se actualizo correctamente en lapos integrado, esto es para no sobreescribir
      // el nombre del estado que contiene el error del envio a actualizar de la primera parte
      if (item.actualizar_correcto == 1) {
        item.estado = det_fp.cobro_ok == 1
        item.estado_nombre = det_fp.error_mensaje
      }

      // actualizo el estado del cobro
      item.estado_cobro = det_fp.estado_id
      item.estado_cobro_color = det_fp.color
      item.estado_cobro_nombre = det_fp.estado_nombre
      item.cob_tipo_transaccion_id = det_fp.cob_tipo_transaccion_id
      item.sol_cobro_estado_id = det_fp.sol_cobro_estados_id

      // controlamos si el estado del cobro en oracle es con error, si fuera asi paso a hacer el envio de la solicitud nuevamente, ya que la misma desaparece si se procesa con error
      if ((item.estado_cobro == 5 || item.estado_cobro == 1) && this.anular != 1 && error == 0) {
        this.$store.state.loading_lps = true
        await this.$store.dispatch('laposIntegrado/enviar_pago', item.sol_id)
        this.$store.state.loading_lps = false
      }

      // mandamos nuevamente la anulacion si el cobro esta aprobado y mandan a sincronizar
      if (item.estado_cobro == 2 && this.anular == 1 && error == 0) {
        this.$store.state.loading_lps = true
        await this.$store.dispatch('laposIntegrado/enviar_anular', item.sol_id)
        this.$store.state.loading_lps = false
      }

      // marcar como anuladas las que estan con error cuando el usuario está anulando
      if (item.estado_cobro == 5 && this.anular == 1 && error == 0) {
        this.$store.state.loading_lps = true
        let result = await this.$store.dispatch('laposIntegrado/set_anular_oracle', item.sol_id)
        this.$store.state.loading_lps = false

        if (result.exito == 1) {
          // lo marque exitosamente como anulado, por lo tanto marco el nuevo estado aqui en el array
          item.estado_cobro = 4
          item.estado_cobro_nombre = 'ANULADO'
          item.estado = true
          item.estado_nombre = 'OK'
        }
      }

      // termina de actualizar los estados, paso a controlar que todos esten con estado OK según la acción del usuario
      this.controlLaposIntegrado()

    },
    async controlLaposIntegrado () {
      let error = 0
      this.show_alert = false

      // aqui voy a decidir si todos los detalles marcados como lapos integrados estan cobrados ok, si los estan entonces cierro el modal y emito exito
      for (let detalle of this.detalles) {
        if (!detalle.estado || detalle.actualizar_correcto != 1) {
          error++
        }
        if (this.anular != 1) {
          if (detalle.estado_cobro != 2) error ++
        } else {
          if (detalle.estado_cobro != 4) error++
        }
      }

      if (error == 0) {
        this.$store.dispatch('show_snackbar', {
          text: 'Todos los detalles se procesaron correctamente',
          color: 'success'
        })
        if (this.anular != 1) {
          this.dialog = false
          this.$emit('cobrok')
        } else {
          this.dialog = false
          this.$emit('anular')
        }
      } else {
        this.show_alert = true
      }
    },
    async ejecutarAnulaciones (errores, opcion) {
      if (errores > 0) {
        this.lock_btn = true
        this.$swal.fire({
          icon: 'error',
          title: 'No se puede continuar con la Anulación hasta no tener errores de Actualización en los estados de los Cobros'
        })
        return
      }

      let error = 0
      // se actualizaron correctamente todos los detalles, por lo tanto tengo en todos el ultimo estado de oracle
      for (let detalle of this.detalles) {
        if (detalle.estado_cobro != 2 && detalle.estado_cobro != 4) {
          // puesto que solo se deja la solicitud en estado pendiente cuando el envio del cobro fue correcto y NUNCA se proceso, es decir, nunca se hizo el intento de cobrar
          // se decide enviar la cancelacion, ya que si el estado de la solicitud cambia a error, o sea 5, es porque o no se envio o bien se proceso con error, por lo tanto
          // ya no figura en el LaPos del vendedor
          if (detalle.estado_cobro == 1) {
            this.$store.state.loading_lps = true
            let result = await this.$store.dispatch('laposIntegrado/cancelar_pago', detalle.sol_id)
            this.$store.state.loading_lps = false

            if (result.exito != 1) {
              error++
            }
          } else if (opcion == 2) {
            // solo mando anulaciones cuando quiera anular con cobro, opcion = 2 es que quieren anular con cobro
            this.$store.state.loading_lps = true
            let result = await this.$store.dispatch('laposIntegrado/set_anular_oracle', detalle.sol_id)
            this.$store.state.loading_lps = false

            if (result.exito != 1) {
              error++
            }
          }
        } else if (opcion == 2) {
          // esta aprobado o anulado, si esta anulado no me interesa, por lo tanto solo pregunto si esta aprobado
          if (detalle.estado_cobro == 2) {
            this.$store.state.loading_lps = true
            let result = await this.$store.dispatch('laposIntegrado/enviar_anular', detalle.sol_id)
            this.$store.state.loading_lps = false

            if (result.exito != 1) {
              error++
            }
          }
        }
      }

      if (error > 0) {
        this.lock_btn = true
        this.$swal.fire({
          icon: 'error',
          title: 'Se produjeron errores al enviar las anulaciones y/o cancelaciones',
          text: 'Reintente la anulación de la Venta'
        })
        return
      }

      // si no hubo errores entonces bloqueo el boton editar y anular
      this.lock_editar = true
      this.lock_anular = true

      // FIN DE PROCEDIMIENTO, YA SE ENVIARON LAS CANCELACIONES O ANULACIONES POR LO CUAL AHORA DEBEN SINCRONIZAR TODOS LOS DETALLES HASTA TENER TODOS ANULADOS O CANCELADOS
      if (opcion == 3) {
        // quiere decir que estan anulando sin cobros, paso a liberar cobros
        this.dialog = false
        this.$emit('libera')
      }
    },
    async anularVenta () {
      let json_modal = {
        icon: 'warning',
        title: `¿Está seguro de anular la Venta N° ${this.venta_id}?`,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
        allowEnterKey: false
      }

      if (this.anular_todo == 0 && this.liberar_todo == 0) {
        json_modal.input = 'radio'
        json_modal.text = 'Anular con Cobros (Online y/o Integrado):',
        json_modal.inputOptions = {
          0: 'NO',
          1: 'SI'
        }
        json_modal.inputValidator = (value) => {
          if (!value) {
            return 'Debe seleccionar una opción'
          }
        }
      }

      // muestro el modal de confirmación
      let modal = await this.$swal.fire(json_modal)
      if (!modal.isConfirmed) return

      this.anular = 1
      if (this.anular_todo == 0 && this.liberar_todo == 0) {
        // si elije la primera vez que anula todo y llega a haber un error, la proxima ya no lo dejo decidir si anular con cobro o no
        if (modal.value == 1) {
          // confirma anular con cobros
          this.anular_todo = 1
          this.actualizarDetalles(2, 1)
        } else {
          // no quiere anular con cobros, paso a liberarlos
          this.liberar_todo = 1
          this.actualizarDetalles(3, 1)
        }
      } else if (this.anular_todo == 1) {
        this.actualizarDetalles(2, 1)
      } else {
        this.actualizarDetalles(3, 1)
      }
    }
  }
}
</script>